import React, { useState, useEffect } from 'react';

import { useTheme } from '@mui/material/styles';

import {
  Box,
  Grid,
  Skeleton,
  useMediaQuery,
  Typography,
  Paper,
  Divider,
} from '@mui/material';

import { useDialogDispatcher } from 'providers/DialogProvider/hooks/useDialogDispatcher';
import httpRoutes from 'utils/httpRoutes';
import { useCallService } from 'hooks';
import Cal from 'assets/images/icons/cal.svg';
import Users from 'assets/images/icons/users.svg';
import Dropdown from 'assets/images/icons/dropdown.svg';
import DropdownInvert from 'assets/images/icons/dropdown-invert.svg';
import Close from 'assets/images/icons/close.svg';
import Right from 'assets/images/icons/right.svg';
import RightArrow from 'assets/images/icons/right-s.svg';
import DownArrow from 'assets/images/icons/down-arrow.svg';
import _ from 'lodash';
import { UserAssignmentType } from 'providers/AssignCourseProvider/contexts/AssignCourseContext';

type AssignmentType = {
  name: string;
  assignmentType: string;
  userAssignmentType: UserAssignmentType;
  groupCourseAssignmentsDetails: any[];
  assignmentDate: Date;
  userCourseAssignmentsDetails: any[];
  coursesAssignations: any[];
};

const today = new Date();

const AssignmentHeader = ({
  userAssignmentType,
  groupCourseAssignmentsDetails,
  userCourseAssignmentsDetails,
}: {
  userAssignmentType: UserAssignmentType;
  groupCourseAssignmentsDetails: any[];
  userCourseAssignmentsDetails: any[];
}) => {
  return (
    <Grid item xs={6} textAlign={'center'} alignContent={'center'}>
      {userAssignmentType === UserAssignmentType.Group ? (
        <>
          <Box sx={{ fontSize: '35px' }}>
            {groupCourseAssignmentsDetails.length}
          </Box>
          <Box mt={2}>
            {groupCourseAssignmentsDetails.length > 1
              ? 'Communities'
              : 'Community'}
          </Box>
        </>
      ) : (
        <>
          <Box sx={{ fontSize: '35px' }}>
            {userCourseAssignmentsDetails.length}
          </Box>
          <Box mt={2}>
            {userCourseAssignmentsDetails.length > 1 ? 'Users' : 'User'}
          </Box>
        </>
      )}
    </Grid>
  );
};

const GroupCourseAssignment = ({
  groupCourseAssignmentsDetails,
}: {
  groupCourseAssignmentsDetails: any[];
}) => {
  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Grid
      item
      sx={{
        height: matchDownSM ? '100px' : '200px',
        overflowY: 'auto',
      }}
    >
      {groupCourseAssignmentsDetails.map((group: any) => {
        return (
          <Box key={group.groupId} m={2}>
            <Typography
              sx={{
                marginLeft: '10px',
                fontFamily: 'Raleway',
                fontWeight: '600',
                fontSize: '15px',
              }}
            >
              {group.name}
            </Typography>

            <Typography
              sx={{
                marginLeft: '20px',
                fontWeight: '600',
                fontSize: '15px',
                backgroundColor: '#F3F7FF',
                color: '#508AC2',
                width: '100%',
              }}
            >
              <img style={{ marginRight: '10px' }} src={Right} alt="right" />
              {group.learners} total learners
            </Typography>
          </Box>
        );
      })}
    </Grid>
  );
};

const UserCourseAssignment = ({
  userCourseAssignmentsDetails,
}: {
  userCourseAssignmentsDetails: any[];
}) => {
  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Grid
      item
      sx={{
        height: matchDownSM ? '100px' : '200px',
        overflowY: 'auto',
      }}
    >
      {userCourseAssignmentsDetails.map((user: any) => {
        return (
          <Box key={user.userId} m={2}>
            <Typography
              sx={{
                marginLeft: '10px',
                fontFamily: 'Raleway',
                fontWeight: '600',
                fontSize: '15px',
              }}
            >
              {user.name}
            </Typography>

            <Typography
              sx={{
                marginLeft: '20px',
                fontWeight: '600',
                fontSize: '15px',
                backgroundColor: '#F3F7FF',
                color: '#508AC2',
                width: '100%',
              }}
            >
              <img style={{ marginRight: '10px' }} src={Right} alt="right" />
              Email: {user.email}
            </Typography>
          </Box>
        );
      })}
    </Grid>
  );
};

const Assignment = ({ assignmentId }: { assignmentId: string }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isShowDetail, setIsShowDetail] = useState(false);
  const [defaultValues, setDefaultValues] = useState<AssignmentType>({
    name: '',
    assignmentType: '',
    userAssignmentType: UserAssignmentType.Group,
    groupCourseAssignmentsDetails: [],
    assignmentDate: today,
    userCourseAssignmentsDetails: [],
    coursesAssignations: [],
  });

  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down('sm'));

  const { callService } = useCallService();
  const { hideDialog } = useDialogDispatcher();

  useEffect(() => {
    const getItem = async () => {
      setIsLoading(true);
      const { response } = await callService({
        resource: httpRoutes.assignCourse.getSummaryDetails(assignmentId),
      });

      if (response) {
        const {
          name,
          assignmentDate,
          assignmentType,
          groupCourseAssignments,
          userCourseAssignments,
        } = response;

        const userAssignmentType =
          response.groupCourseAssignments.length > 0
            ? UserAssignmentType.Group
            : UserAssignmentType.Users;

        const groupCourseAssignmentsDetails = _.uniqBy(
          groupCourseAssignments,
          'groupId'
        ).map((groupCourseAssignment: any) => {
          return {
            name: groupCourseAssignment.group.name,
            learners: groupCourseAssignment.userAssignments.length,
            groupId: groupCourseAssignment.group.id,
          };
        });

        const userCourseAssignmentsDetails = _.uniqBy(
          userCourseAssignments,
          'userId'
        )
          .filter((x: any) => x.userAssignment !== null)
          .map((userCourseAssignment: any) => {
            const user = userCourseAssignment.userAssignment.user;
            return {
              name: `${user.firstName} ${user.lastName}`,
              email: user.email,
              userId: user.id,
            };
          });

        const coursesAssignations =
          response.groupCourseAssignments.length > 0
            ? _.uniqBy(response.groupCourseAssignments, 'courseId').map(
                (groupCourseAssignment: any) => ({
                  courseId: groupCourseAssignment.course.id,
                  courseName: groupCourseAssignment.course.name,
                  daysToComplete: groupCourseAssignment.daysToComplete,
                })
              )
            : _.uniqBy(response.userCourseAssignments, 'courseId').map(
                (userCourseAssignment: any) => ({
                  courseId: userCourseAssignment.courseId,
                  courseName: userCourseAssignment.course.name,
                  daysToComplete: userCourseAssignment.daysToComplete,
                })
              );

        setDefaultValues({
          name,
          assignmentType,
          userAssignmentType,
          assignmentDate: new Date(assignmentDate),
          groupCourseAssignmentsDetails,
          userCourseAssignmentsDetails,
          coursesAssignations,
        });
        setIsLoading(false);
      }
    };

    if (assignmentId) {
      getItem();
    }
  }, [assignmentId]);

  if (isLoading) {
    return (
      <Box sx={{ width: '100%' }}>
        <Skeleton
          variant="rectangular"
          height={matchDownSM ? '550px' : '620px'}
          width="100%"
        />
      </Box>
    );
  }

  const handleShowDetail = () => {
    setIsShowDetail(!isShowDetail);
  };

  return (
    <Box>
      <Grid
        container
        flexDirection={'row'}
        spacing={2}
        p={2}
        justifyContent={'center'}
      >
        <Grid container justifyContent={'flex-end'} spacing={2}>
          <Grid item xs={1} textAlign={'end'}>
            <img
              onClick={hideDialog}
              src={Close}
              alt="close"
              style={{ cursor: 'pointer' }}
            />
          </Grid>
        </Grid>
        <Grid
          container
          justifyContent={'center'}
          alignContent={'center'}
          mt={1}
          mb={4}
          spacing={2}
        >
          <Grid
            item
            mb={1}
            sx={{
              fontFamily: 'Raleway',
              fontWeight: '600',
              fontSize: matchDownSM ? '20px' : '32px',
              color: '#508AC2',
              textAlign: 'center',
              height: '5vh',
            }}
            xs={12}
          >
            {defaultValues.name}
          </Grid>
          <Grid item>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography
                sx={{
                  backgroundColor: '#F3F7FF',
                  color: '#508AC2',
                  marginRight: '28px',
                }}
              >
                <img src={Cal} alt="Calendar" />{' '}
                {defaultValues.assignmentDate.toLocaleDateString()}
              </Typography>
              <Typography sx={{ backgroundColor: '#F3F7FF', color: '#508AC2' }}>
                <img src={Users} alt="Users" />{' '}
                {defaultValues.assignmentType === 'CurrentAndFuture'
                  ? 'Current And Future'
                  : defaultValues.assignmentType}
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Grid
          container
          mt={1}
          sx={{ border: '2px solid', borderColor: 'divider' }}
          flexDirection={'row'}
          justifyContent={'center'}
          alignItems={'center'}
        >
          <Typography
            variant="body1"
            sx={{
              position: 'absolute',
              top: '165px', // Position between the top border
              left: '50%',
              transform: 'translateX(-50%)',
              backgroundColor: 'white', // Background color to hide border line
              padding: '0 8px',
              fontWeight: 'bold',
              fontSize: '14px',
            }}
          >
            AFFECTS
          </Typography>
          <Grid
            container
            item
            height={'15vh'}
            justifyContent={'center'}
            sx={{
              fontFamily: 'Raleway',
              fontWeight: '600',
              fontSize: '20px',
            }}
          >
            <AssignmentHeader
              userAssignmentType={defaultValues.userAssignmentType}
              groupCourseAssignmentsDetails={
                defaultValues.groupCourseAssignmentsDetails
              }
              userCourseAssignmentsDetails={
                defaultValues.userCourseAssignmentsDetails
              }
            />
            <Grid
              item
              xs={6}
              textAlign={'center'}
              alignContent={'center'}
              sx={{ borderLeft: '1px solid', borderColor: 'divider' }}
            >
              <Box sx={{ fontSize: '35px' }}>
                {defaultValues.coursesAssignations.length}
              </Box>
              <Box mt={2}>
                {defaultValues.coursesAssignations.length > 1
                  ? 'Courses'
                  : 'Course'}
              </Box>
            </Grid>
          </Grid>
          <Grid
            container
            item
            justifyContent={'center'}
            alignItems={'center'}
            height="40px"
            sx={{
              borderTop: '2px dotted',
              borderBottom: isShowDetail ? '2px dotted' : 'none',
              borderColor: 'divider',
              fontFamily: 'Raleway',
              fontWeight: '600',
              fontSize: '18px',
            }}
          >
            <Grid item xs={12} textAlign={'center'}>
              <Typography
                onClick={handleShowDetail}
                sx={{
                  cursor: 'pointer',
                  fontWeight: '600',
                  fontSize: '15px',
                }}
              >
                {isShowDetail ? 'See Less' : 'See Details'}
                {isShowDetail ? (
                  <img
                    style={{ marginLeft: '10px' }}
                    height="8px"
                    src={DropdownInvert}
                    alt="dropdown"
                  />
                ) : (
                  <img
                    style={{ marginLeft: '10px' }}
                    height="8px"
                    src={Dropdown}
                    alt="dropdown"
                  />
                )}
              </Typography>
            </Grid>
          </Grid>
          {isShowDetail && (
            <>
              <Grid
                container
                item
                sx={{
                  borderBottom: '1px solid',
                  borderColor: 'divider',
                  fontFamily: 'Raleway',
                  fontWeight: '600',
                  fontSize: '15px',
                }}
              >
                <Grid item xs={12} sm={4}>
                  <Grid
                    item
                    sx={{
                      height: '40px',
                      borderBottom: '1px solid ',
                      borderColor: 'divider',
                      paddingTop: '10px',
                    }}
                    alignItems={'center'}
                    textAlign={'center'}
                  >
                    These{' '}
                    {defaultValues.userAssignmentType ===
                    UserAssignmentType.Group
                      ? 'Communities'
                      : 'Users'}{' '}
                    ...
                  </Grid>
                  {defaultValues.userAssignmentType ===
                  UserAssignmentType.Group ? (
                    <GroupCourseAssignment
                      groupCourseAssignmentsDetails={
                        defaultValues.groupCourseAssignmentsDetails
                      }
                    />
                  ) : (
                    <UserCourseAssignment
                      userCourseAssignmentsDetails={
                        defaultValues.userCourseAssignmentsDetails
                      }
                    />
                  )}
                </Grid>
                <Grid item xs={12} sm={0.7}>
                  {!matchDownSM && (
                    <Grid
                      item
                      sx={{
                        height: '40px',
                        borderBottom: '1px solid',
                        borderColor: 'divider',
                      }}
                    ></Grid>
                  )}
                  <Divider
                    orientation={matchDownSM ? 'horizontal' : 'vertical'}
                    // flexItem
                    sx={{ height: matchDownSM ? '35px' : '200px' }}
                  >
                    <Box
                      sx={{
                        width: 30, // Adjust the size as needed
                        height: 30, // Adjust the size as needed
                        borderRadius: '50%',
                        overflow: 'hidden',
                        border: '1px solid', // Adjust the border style as needed
                        borderColor: 'divider',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        p: 0,
                        m: 0,
                      }}
                    >
                      <img
                        src={matchDownSM ? DownArrow : RightArrow}
                        alt="rightArraow"
                      />
                    </Box>
                  </Divider>
                </Grid>
                <Grid item xs={12} sm={7.3}>
                  <Grid
                    item
                    sx={{
                      borderBottom: '1px solid ',
                      // borderBottom: '2px solid',
                      borderColor: 'divider',
                      height: '40px',
                      paddingTop: '10px',
                    }}
                    alignItems={'center'}
                    textAlign={'center'}
                  >
                    Are assigned these courses...
                  </Grid>
                  <Grid
                    item
                    sx={{
                      height: '200px',
                      overflowY: 'auto',
                    }}
                  >
                    {defaultValues.coursesAssignations.map((course: any) => {
                      return (
                        <Paper
                          variant="outlined"
                          key={course.course}
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            border: 'none',
                            backgroundColor: '#F3F7FF',
                            color: '#508AC2',
                            margin: 1,
                            fontFamily: 'Raleway',
                            fontWeight: '600',
                            fontSize: '20px',
                          }}
                        >
                          <Typography
                            sx={{
                              padding: 2,
                              width: '70%',
                              fontFamily: 'Raleway',
                              fontWeight: '600',
                              fontSize: '15px',
                            }}
                          >
                            {course.courseName}
                          </Typography>
                          <Divider orientation="vertical" flexItem />
                          <Box
                            sx={{
                              justifyContent: 'center',
                              width: '30%',
                              textAlign: 'center',
                            }}
                          >
                            <Box textAlign={'center'}>
                              <Typography
                                variant="caption"
                                sx={{
                                  textAlign: 'center',
                                  fontWeight: '600',
                                  fontSize: '15px',
                                }}
                              >
                                {course.daysToComplete}
                              </Typography>
                            </Box>
                            <Typography
                              variant="caption"
                              sx={{ textAlign: 'center' }}
                            >
                              DAYS TO COMPLETE
                            </Typography>
                          </Box>
                        </Paper>
                      );
                    })}
                  </Grid>
                </Grid>
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default Assignment;
