import React, { useEffect, useState } from 'react';
import { useDropzone, DropzoneOptions, Accept } from 'react-dropzone';
import { Button, Grid, Typography } from '@mui/material';
import { ImagePlaceholderIcon, PDFFileIcon } from 'material-icons';

import { REACT_APP_CLOUDFRONT_URL } from 'config/config';

interface DropzoneProps {
  handleAcceptedFiles: (values: File[]) => void;
  defaultValue?: string;
  helperText?: string;
  maxFiles?: number;
  maxSize?: number;
}

interface ImageWithPreview extends File {
  preview: string;
}

const INITIAL_STATE: ImageWithPreview[] = [];
const ImagePreview = ({
  source,
  alt,
  key,
}: {
  source: string;
  alt: string;
  key: string;
}) => {
  return (
    <Grid container justifyContent="center" key={key}>
      <img
        src={source}
        alt={alt}
        style={{
          position: 'relative',
          top: 0,
          left: 0,
          width: '400px',
          height: '200px',
          objectFit: 'contain',
        }}
      />
    </Grid>
  );
};

const Dropzone = ({
  handleAcceptedFiles,
  defaultValue,
  helperText,
  maxFiles = 1,
}: DropzoneProps) => {
  const [files, setFiles] = useState(INITIAL_STATE);
  const [showDefaultPreview, setShowDefaultPreview] = useState(!!defaultValue);

  const accept: Accept = {
    'image/*': ['.png', '.jpg', '.jpeg', '.gif'],
    'application/pdf': ['.pdf'],
  };

  const options: DropzoneOptions = {
    accept: accept,
    maxFiles: maxFiles,
    noClick: true,
    onDrop: (acceptedFiles: File[]) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
      handleAcceptedFiles(acceptedFiles);
    },
    onDropRejected: (rejectedFiles: any[]) => {
      const _fileErrors = rejectedFiles.map((errors) => {
        return errors;
      });

      console.log(_fileErrors);
      // setErrors(_fileErrors);
    },
  };

  const { getRootProps, getInputProps, open } = useDropzone(options);

  const previews = files.map((file) => {
    if (file.type === 'application/pdf') {
      return (
        <Grid
          item
          key={file.name}
          sx={{
            maxWidth: '100%',
          }}
        >
          <Grid container justifyContent="center" flexDirection="row">
            <PDFFileIcon sx={{ fontSize: '108px', color: 'gray' }} />
            <Grid container flexDirection="column">
              <Typography textAlign="center" variant="body1" color="error">
                PDF
              </Typography>
              <Typography textAlign="center" variant="caption">
                {file.name}
              </Typography>
              <Button onClick={() => setFiles([])}>REMOVE</Button>
            </Grid>
          </Grid>
        </Grid>
      );
    }
    return (
      <ImagePreview source={file.preview} alt={file.name} key={file.name} />
    );
  });

  useEffect(() => {
    files.forEach((file) => URL.revokeObjectURL(file.preview));
  }, []);

  return (
    <Grid
      {...getRootProps()}
      sx={{
        mt: 1,
        mb: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        borderWidth: 2,
        borderRadius: 2,
        borderColor: '#eeeeee',
        borderStyle: 'dashed',
        background: 'repeating-linear-gradient(45deg, lightGray, white 10%)',
        width: '400px',
        height: '200px',
        color: '#bdbdbd',
        outline: 'none',
        transition: 'border .24s ease-in-out',
        position: 'relative',
        cursor: 'pointer',
      }}
      onClick={open} // Ahora el selector solo se abre si se hace clic dentro del área del dropzone
    >
      <input {...getInputProps()} style={{ display: 'none' }} />
      {files.length < 1 && !showDefaultPreview && (
        <Grid container flexDirection="column" alignItems="center">
          <ImagePlaceholderIcon sx={{ fontSize: '64px' }} />
          <Typography variant="caption" textAlign="center">
            {helperText || 'Drag and drop files here, or click to select'}
          </Typography>
        </Grid>
      )}
      {files.length > 0 &&
        files.map((file) =>
          file.type === 'application/pdf' ? (
            <Grid item key={file.name}>
              <Grid container justifyContent="center" flexDirection="row">
                <PDFFileIcon sx={{ fontSize: '108px', color: 'gray' }} />
                <Grid container flexDirection="column">
                  <Typography textAlign="center" variant="body1" color="error">
                    PDF
                  </Typography>
                  <Typography textAlign="center" variant="caption">
                    {file.name}
                  </Typography>
                  <Button onClick={() => setFiles([])}>REMOVE</Button>
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <ImagePreview
              source={file.preview}
              alt={file.name}
              key={file.name}
            />
          )
        )}
      {files.length < 1 && defaultValue && showDefaultPreview && (
        <ImagePreview
          source={`${REACT_APP_CLOUDFRONT_URL}/${defaultValue}`}
          alt="Current Image"
          key="Current Image"
        />
      )}
    </Grid>
  );
};

export default Dropzone;
