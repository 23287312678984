import React from 'react';
import { Box, Typography } from '@mui/material';

const AssignmentsGuide = () => {
  return (
    <Box
      sx={{
        fontFamily: 'Roboto, sans-serif',
        maxWidth: 1000,
        mx: 'auto',
        p: 3,
        bgcolor: 'whitesmoke',
        textAlign: 'center',
        borderRadius: 2,
        border: '1px solid purple',
      }}
    >
      <Typography variant="h2" sx={{ fontSize: 22, fontWeight: 600, mb: 3 }}>
        📘 How Assignments Work
      </Typography>
      <Typography
        variant="h3"
        sx={{
          mb: 4,
          fontWeight: 700,
          textAlign: 'center',
          color: 'text.primary',
          fontFamily: 'Raleway',
        }}
      >
        Before you proceed, here&apos;s a quick guide to understand how
        assignments are applied to learners.
      </Typography>

      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 4 }}>
        {/* Left column */}
        <Box sx={{ flex: 1, minWidth: 300 }}>
          <Typography
            variant="h3"
            sx={{ mt: 0, fontSize: 18, fontWeight: 600, mb: 2 }}
          >
            🔝 Priority Rules (Who gets the course?)
          </Typography>
          <Box
            component="ul"
            sx={{ lineHeight: 1.6, m: 0, pl: '20px', textAlign: 'left', mb: 2 }}
          >
            <li>
              <strong>✅ Direct-to-User:</strong> Highest priority. Overrides
              all other assignment types. Ensures specific user targeting.
            </li>
            <li>
              <strong>🏠 Primary Community:</strong> Second priority. Applies
              when no direct assignment exists. Requires valid primary community
              association.
            </li>
            <li>
              <strong>🌐 Other Communities:</strong> Lowest priority. Used when
              no higher-priority assignments exist. Must belong to the
              user&apos;s secondary communities.
            </li>
          </Box>

          <Typography
            sx={{
              color: '#664D03',
              fontStyle: 'italic',
              textAlign: 'left',
              fontSize: 14,
              mb: 4,
              backgroundColor: '#FFF3CD',
              p: 1,
              borderRadius: 1,
              border: '1px solid',
              borderColor: '#FFE69C',
              display: 'flex',
              alignItems: 'center',
              gap: 1,
            }}
          >
            ⚠️ Only one active assignment per user is maintained based on these
            priority rules.
          </Typography>

          <Typography
            variant="h3"
            sx={{ fontSize: 18, fontWeight: 600, mb: 2 }}
          >
            👥 Community Assignment Rules
          </Typography>
          <Box
            component="ul"
            sx={{ lineHeight: 1.6, m: 0, pl: '20px', textAlign: 'left', mb: 4 }}
          >
            <li>Active learner status (not pending/manager/archived)</li>
            <li>Established primary community</li>
            <li>No prior course completion</li>
            <li>Valid bundle association</li>
            <li>No existing higher-priority assignment</li>
          </Box>

          <Typography
            variant="h3"
            sx={{ fontSize: 18, fontWeight: 600, mb: 2 }}
          >
            📚 Bundle Validation
          </Typography>
          <Box
            component="ul"
            sx={{ lineHeight: 1.6, m: 0, pl: '20px', textAlign: 'left' }}
          >
            <li>Course must be included in community bundle</li>
            <li>System automatically skips invalid assignment</li>
            <li>Regularly validating course bundles is recommended</li>
          </Box>
        </Box>

        {/* Right column */}
        <Box sx={{ flex: 1, minWidth: 300 }}>
          <Typography
            variant="h3"
            sx={{ mt: 0, fontSize: 18, fontWeight: 600, mb: 2 }}
          >
            ⏳ How Due Dates Work
          </Typography>
          <Typography sx={{ lineHeight: 1.6, textAlign: 'left', mb: 2 }}>
            When you assign a course, you also choose how many days the learner
            has to complete it. The system calculates the due date by adding the
            number of days you set to the assignment’s creation date.
            <br />
            <br />
            <strong>📅 Example:</strong>
            <br />
          </Typography>

          <Typography
            sx={{
              color: '#055160',
              fontStyle: 'italic',
              textAlign: 'left',
              fontSize: 14,
              mb: 4,
              backgroundColor: '#CFF4FC',
              p: 1,
              borderRadius: 1,
              border: '1px solid',
              borderColor: '#B6EFFB',
              display: 'flex',
              alignItems: 'center',
              gap: 1,
            }}
          >
            ℹ️ 5-day window = Due in 5 days from date of assignment
          </Typography>

          <Typography
            variant="h3"
            sx={{ fontSize: 18, fontWeight: 600, mb: 2 }}
          >
            ⚖️ What if the same course is assigned twice?
          </Typography>
          <Typography sx={{ lineHeight: 1.6, textAlign: 'left', mb: 2 }}>
            If the learner receives the same course from two places with the
            same priority (e.g. two other communities), the system will:
            <br />✅ Choose the one with the earliest due date.
            <br />
            <br />
            <strong>🎯 Use this to your advantage:</strong> Shorter due dates
            will “win” if both assignments are equal in priority.
          </Typography>

          <Typography
            variant="h3"
            sx={{ fontSize: 18, fontWeight: 600, mb: 2 }}
          >
            ✏️ Editing Assignments
          </Typography>
          <Box
            component="ul"
            sx={{ lineHeight: 1.6, m: 0, pl: '20px', textAlign: 'left' }}
          >
            <li>
              <strong>Add a course:</strong> Replaces a lower-priority one.
              Ignored if a higher-priority one exists.
            </li>
            <li>
              <strong>Remove a course:</strong> Another assignment may become
              active. If not, the learner keeps access, but without a due date.
            </li>
            <li>
              <strong>Change due date:</strong> Applied only if it comes from a
              higher-priority source.
            </li>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default AssignmentsGuide;
