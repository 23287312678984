import { FC } from 'react';
import { Box, Typography } from '@mui/material';
import { useAssignCourseDispatcher } from 'providers/AssignCourseProvider/hooks/useAssignCourseDispatcher';
import {
  AssignmentType,
  UserAssignmentType,
} from 'providers/AssignCourseProvider/contexts/AssignCourseContext';
import AssignmentTooltip from 'features/course-assignment/atomic/AssignmentTooltip';

const Summary: FC = () => {
  const { assignCourseState } = useAssignCourseDispatcher();
  const {
    name,
    groupsIds,
    courseAssignmentsDuration,
    assignmentType,
    learners,
    userAssignmentType,
    usersIds,
  } = assignCourseState;

  const getAssignmentCount = () => {
    const count =
      userAssignmentType === UserAssignmentType.Group
        ? groupsIds.length
        : usersIds.length;
    const type =
      userAssignmentType === UserAssignmentType.Group
        ? count > 1
          ? 'Communities'
          : 'Community'
        : count > 1
        ? 'Users'
        : 'User';
    return `${count} ${type}`;
  };

  const getCourseCount = () => {
    const count = courseAssignmentsDuration.length;
    return `${count} ${count > 1 ? 'Courses' : 'Course'}`;
  };

  const assignmentTypeLabel = () => {
    switch (assignmentType) {
      case AssignmentType.Current:
        return `Current Users: This may affect up to ${learners} current users`;
      case AssignmentType.Future:
        return 'Future Users: This will only affect new users and no current users';
      case AssignmentType.CurrentAndFuture:
        return `Current and Future Users: This may affect up to ${learners} current users and ALL NEW users`;
      default:
        return '';
    }
  };

  return (
    <Box
      sx={{
        maxWidth: 600,
        margin: 'auto',
        fontFamily: 'sans-serif',
      }}
    >
      <Typography
        variant="h3"
        sx={{
          fontSize: 28,
          fontWeight: 700,
          color: '#222',
          mb: 3,
          textAlign: 'center',
        }}
      >
        Assignment Summary
      </Typography>

      <Typography
        sx={{
          fontSize: 18,
          color: '#444',
          mb: 4,
          textAlign: 'center',
          fontFamily: 'Raleway',
        }}
      >
        Please review your assignment before saving
      </Typography>

      <Typography
        variant="h2"
        sx={{
          fontSize: 24,
          fontWeight: 700,
          color: '#222',
          mb: 2,
        }}
      >
        Assignment: <span style={{ fontWeight: 400 }}>{name}</span>
      </Typography>

      <Typography
        sx={{
          fontSize: 16,
          fontWeight: 500,
          color: '#2e7d32',
          mb: 1,
        }}
      >
        {getAssignmentCount()} | {getCourseCount()}
      </Typography>

      <Box sx={{ mb: 3 }}>
        <AssignmentTooltip />
      </Box>

      <Typography
        sx={{
          fontSize: 14,
          color: '#666',
          lineHeight: 1.5,
          mb: 2,
        }}
      >
        {assignmentTypeLabel()}
      </Typography>

      <Typography
        sx={{
          fontSize: 14,
          color: '#666',
          lineHeight: 1.5,
        }}
      >
        Some users <b>may not be assigned</b> due to their managerial status or
        if they&apos;ve already completed the course.
        <br />
        <br />
        <strong>Please Note:</strong> By clicking confirm, an email will be sent
        to eligible users to inform them of their new assignments.
      </Typography>
    </Box>
  );
};

export default Summary;
