/* eslint-disable */
import { memo, useMemo, useState, ReactNode } from 'react';
import AssignCourseContext, {
  ASSIGN_COURSE_INITIAL_STATE,
  UserAssignmentType,
} from './contexts/AssignCourseContext';
import httpRoutes from 'utils/httpRoutes';
import { useCallService } from 'hooks';
import _ from 'lodash';

const AssignCourseProvider = ({ children }: { children: ReactNode }) => {
  const [assignCourseState, setAssignCourseState] = useState(
    ASSIGN_COURSE_INITIAL_STATE
  );
  const { callService } = useCallService();

  const getOrganizationAssignment = async (
    organizationAssignmentId: number
  ) => {
    try {
      const { response } = await callService({
        resource: httpRoutes.assignCourse.getOne(organizationAssignmentId),
      });

      if (response) {
        const learnersResponse = await callService({
          resource: httpRoutes.assignCourse.getLearners(
            organizationAssignmentId
          ),
        });

        if (!learnersResponse.response) {
          return;
        }

        const coursesAssignations =
          response.groupCourseAssignments.length > 0
            ? _.uniqBy(response.groupCourseAssignments, 'courseId').map(
                (groupCourseAssignment: any) => ({
                  courseId: groupCourseAssignment.course.id,
                  daysToComplete: groupCourseAssignment.daysToComplete,
                })
              )
            : _.uniqBy(response.userCourseAssignments, 'courseId').map(
                (userCourseAssignment: any) => ({
                  courseId: userCourseAssignment.courseId,
                  daysToComplete: userCourseAssignment.daysToComplete,
                })
              );

        const mapUserGroupAssignedIds = (groupCourseAssignments: any) => {
          const userGroupAssignedIds = groupCourseAssignments.reduce(
            (
              acc: { groupId: string; userIds: string[] }[],
              groupCourseAssignment: any
            ) => {
              const groupId = groupCourseAssignment.group.id;
              const userIds = groupCourseAssignment.userAssignments.map(
                (userAssignment: any) => userAssignment.user.id
              );

              const existingGroup = acc.find(
                (item) => item.groupId === groupId
              );

              if (existingGroup) {
                // Merge and deduplicate userIds for existing group
                existingGroup.userIds = [
                  ...new Set([...existingGroup.userIds, ...userIds]),
                ];
                return acc;
              }

              // Add new group with userIds
              acc.push({ groupId, userIds });
              return acc;
            },
            []
          );

          return userGroupAssignedIds;
        };

        setAssignCourseState((prevState: any) => ({
          ...prevState,
          organizationAssignmentId: organizationAssignmentId,
          organizationId: response.organizationId,
          name: response.name,
          groupsIds:
            response.groupCourseAssignments.length > 0
              ? [
                  ...new Set(
                    response.groupCourseAssignments.map(
                      (groupCourseAssignment: any) =>
                        groupCourseAssignment.group.id
                    )
                  ),
                ]
              : [],
          usersIds:
            response.userCourseAssignments.length > 0
              ? [
                  ...new Set(
                    response.userCourseAssignments.map(
                      (userCourseAssignment: any) => userCourseAssignment.userId
                    )
                  ),
                ]
              : [],
          assignmentType: response.assignmentType,
          userAssignmentType:
            response.groupCourseAssignments.length > 0
              ? UserAssignmentType.Group
              : UserAssignmentType.Users,
          courseAssignmentsDuration: coursesAssignations,
          learners: learnersResponse.response.learners,
          userGroupAssignedIds:
            response.groupCourseAssignments.length > 0
              ? mapUserGroupAssignedIds(response.groupCourseAssignments)
              : [],
          userAssignmentIds:
            response.groupCourseAssignments.length > 0
              ? response.groupCourseAssignments.map(
                  (groupCourseAssignment: any) =>
                    groupCourseAssignment.userAssignments.map(
                      (userAssignment: any) => userAssignment.id
                    )
                )
              : response.userCourseAssignments
                  .filter((x: any) => x.userAssignment !== null)
                  .map(
                    (userCourseAssignment: any) =>
                      userCourseAssignment.userAssignment.id
                  ),
          fetching: false,
        }));
      }
    } catch (e) {
      console.log(e);
    }
  };

  const value = useMemo(
    () => ({
      assignCourseState,
      setAssignCourseState,
      getOrganizationAssignment,
    }),
    [assignCourseState, setAssignCourseState, getOrganizationAssignment]
  );

  return (
    <AssignCourseContext.Provider value={value}>
      {children}
    </AssignCourseContext.Provider>
  );
};

export default memo(AssignCourseProvider);
