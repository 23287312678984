import { Box, Typography, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import WarningIcon from '@mui/icons-material/Warning';
import { useState } from 'react';

export const EnvironmentWarning = () => {
  const [isVisible, setIsVisible] = useState(true);

  if (!isVisible) return null;

  return (
    <Box
      sx={{
        backgroundColor: 'info.main',
        color: 'info.contrastText',
        p: 2,
        borderRadius: 2,
        position: 'fixed',
        top: 16,
        right: 16,
        zIndex: 1200,
        boxShadow: '0 4px 20px rgba(0, 0, 0, 0.25)',
        maxWidth: '400px',
        display: 'flex',
        alignItems: 'center',
        gap: 2,
        animation: 'pulse 2s infinite',
        '@keyframes pulse': {
          '0%': { boxShadow: '0 4px 20px rgba(0, 0, 0, 0.25)' },
          '50%': { boxShadow: '0 4px 30px rgba(33, 150, 243, 0.5)' },
          '100%': { boxShadow: '0 4px 20px rgba(0, 0, 0, 0.25)' },
        },
      }}
    >
      <WarningIcon
        fontSize="medium"
        sx={{
          color: '#FFD700',
          animation: 'warningPulse 1.5s infinite',
          '@keyframes warningPulse': {
            '0%': { color: '#FFD700' },
            '50%': { color: '#FFA500' },
            '100%': { color: '#FFD700' },
          },
        }}
      />
      <Typography variant="subtitle2" fontWeight="bold">
        <u>
          <b>ATENTION</b>
        </u>
        : You are currently in {window.location.hostname.toUpperCase()}{' '}
        environment. This is <b>not production</b>!
      </Typography>
      <IconButton
        size="small"
        onClick={() => setIsVisible(false)}
        sx={{
          color: 'info.contrastText',
          '&:hover': {
            backgroundColor: 'info.dark',
          },
        }}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </Box>
  );
};
