import React, { useEffect, useState } from 'react';

import { useCallService } from 'hooks';

import httpRoutes from 'utils/httpRoutes';

import TopLearnersList from '../../../../shared/topLearners/TopLearners';

const TopLearners = ({
  includesFacilitators = false,
}: {
  includesFacilitators?: boolean;
}) => {
  const [learners, setLearners] = useState([]);
  const [loading, setLoading] = useState(true);

  const { callService } = useCallService();

  const getLearners = async () => {
    try {
      const { response } = await callService({
        resource: httpRoutes.reporting.group.getTopLearners(),
      });

      if (response) {
        setLearners(response);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getLearners();
  }, []);

  return (
    <TopLearnersList
      loading={loading}
      topLearners={learners}
      includesFacilitators={includesFacilitators}
    />
  );
};

export default TopLearners;
