import React from 'react';

import { Controller, FieldError } from 'react-hook-form';

import { Box, InputAdornment, TextField } from '@mui/material';

import { SxProps } from '@mui/system';
import Icon from '../../icons';

const EndAdornment = () => (
  <InputAdornment position="end">
    <Icon name="envelope" fill="#71AB8C" />
  </InputAdornment>
);

interface EmailFormFieldProps {
  name: string;
  placeholder?: string;
  control: any;
  errors?: FieldError;
  margin?: 'normal' | 'dense' | 'none';
  required?: boolean;
  sx?: SxProps;
  disabled?: boolean;
  id?: string;
}

const defaultSx = {
  background: 'white',
  borderRadius: '30px',
  '& .MuiOutlinedInput-root': {
    '& :-webkit-autofill': {
      borderRadius: '30px !important',
    },
    '& fieldset': {
      borderRadius: '30px',
    },
    '&:hover fieldset': {
      borderColor: '#56C596',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#56C596',
    },
  },
};

const EmailFormField = ({
  name,
  control,
  errors,
  placeholder,
  margin = 'normal',
  required,
  sx,
  disabled = false,
  id
}: EmailFormFieldProps) => (
  <Controller
    name={name}
    control={control}
    render={({ field }) => (
      <Box>
        <TextField
          {...field}
          sx={{ ...defaultSx, ...sx }}
          type="email"
          fullWidth
          placeholder={placeholder}
          margin={margin}
          required={required}
          error={Boolean(errors)}
          disabled={disabled}
          id={id}
          InputProps={{
            endAdornment: <EndAdornment />,
          }}
        />
        
        <Box
          sx={{
            width: 'fit-content',
            color: '#d32f2f',
            fontWeight: 'bold',
            minHeight: '19px',
          }}
          id={`${id}-error`}
        >
          {errors ? errors.message : ''}
        </Box>
      </Box>
    )}
  />
);

export default EmailFormField;
