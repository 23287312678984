import React from 'react';
import { Avatar, Box, Grid, useMediaQuery } from '@mui/material';
import TopLearnersLoading from './TopLearnersLoading';
import { useTheme } from '@mui/material/styles';

const TopLearners = ({
  loading = false,
  topLearners,
  includesFacilitators = false,
}: {
  loading?: boolean;
  topLearners: any[];
  includesFacilitators?: boolean;
}) => {
  const theme = useTheme();
  const matchUpXS = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Box mb={2}>
        <Box
          sx={{
            fontFamily: 'Raleway',
            fontWeight: '700',
            fontSize: '20px',
            lineHeight: '130%',
            color: '#56C596',
          }}
        >
          TOP {includesFacilitators ? 'MEMBERS' : 'LEARNERS'}
        </Box>
        <Box
          sx={{
            fontFamily: 'Roboto',
            fontWeight: '400',
            fontSize: '12px',
            lineHeight: '130%',
            color: '#445D74',
          }}
        >
          by course completion
        </Box>
      </Box>
      {loading ? (
        <TopLearnersLoading />
      ) : (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          {topLearners.map((learner, index) => {
            const name = learner.name.split(' ');
            const firstLetter = name[0][0];
            const secondLetter = name[0][1];

            return (
              <Grid
                container
                columnSpacing={2}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  borderBottom:
                    index !== topLearners.length - 1
                      ? '1px solid #BCC6CF'
                      : 'none',
                  pb: 1,
                }}
                key={learner.name}
              >
                <Grid item xs={2} md={2}>
                  <Avatar>{`${firstLetter}${secondLetter}`}</Avatar>
                </Grid>
                <Grid
                  item
                  xs={6}
                  md={7}
                  sx={{ display: 'flex', flexDirection: 'column' }}
                >
                  <Box
                    sx={{
                      fontFamily: 'Raleway',
                      fontWeight: '600',
                      fontSize: '16px',
                      lineHeight: matchUpXS ? '20px' : '32px',
                      color: '#162738',
                    }}
                  >
                    {learner.name}
                  </Box>
                  <Box
                    sx={{
                      fontFamily: 'Roboto',
                      fontWeight: '400',
                      fontSize: '12px',
                      lineHeight: '130%',
                      color: '#445D74',
                    }}
                  >
                    {learner.additionalInfo}
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={2}
                  md={2}
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    fontFamily: 'Roboto',
                    fontWeight: '300',
                    fontSize: '40px',
                    lineHeight: '120%',
                    color: '#287E7D',
                  }}
                >
                  {learner.count}
                </Grid>
              </Grid>
            );
          })}
        </Box>
      )}
    </Box>
  );
};

export default TopLearners;
