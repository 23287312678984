import { useState, useEffect } from 'react';

import httpRoutes from 'utils/httpRoutes';
import { useCallService } from 'hooks';

import { SelectOptionProps } from 'components/formFields/types';

const completionRates = Array.from({ length: 5 }, (_, i) => ({
  label: `${60 + i * 10}%`,
  value: 60 + i * 10,
}));

const sampleCompleteCoursesReceives = [
  { value: '1 to 1 Swag', label: '1 to 1 Swag' },
  { value: 'Completion Rate', label: 'Completion Rate' },
];

const swagCompleteCoursesReceives = [
  { value: '1 to 1 Swag', label: '1 to 1 Swag' },
  { value: 'Completion Rate', label: 'Completion Rate' },
];

const giveawayAvailable = [
  { label: 'Yes', value: 'yes' },
  { label: 'No', value: 'no' },
];

const daysFromLaunch = [
  { label: '30', value: '30' },
  { label: '60', value: '60' },
  { label: '90', value: '90' },
];

const userIncentivesOptions = () => {
  const { callService } = useCallService();

  const [rewardTypes, setRewardTypes] = useState<SelectOptionProps[]>([]);

  const [isFetching, setIsFetching] = useState(true);

  const getRewardTypes = async () => {
    const { response } = await callService({
      resource: httpRoutes.rewardTypes.getAll(),
    });

    return response
      ? response.map((item: { id: string; name: string }) => ({
          value: item.id,
          label: item.name,
        }))
      : [];
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [rewardTypes] = await Promise.all([getRewardTypes()]);
        setRewardTypes(rewardTypes);
      } catch (error) {
        console.log(error);
      } finally {
        setIsFetching(false);
      }
    };

    fetchData();
  }, []);

  return {
    rewardTypes,
    completionRates,
    giveawayAvailable,
    swagCompleteCoursesReceives,
    sampleCompleteCoursesReceives,
    daysFromLaunch,
    isFetching,
  };
};

export default userIncentivesOptions;
