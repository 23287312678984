import { ReactNode, useCallback, memo } from 'react';
import {
  Box,
  Grid,
  Tab,
  Tabs,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import { useAssignCourseDispatcher } from 'providers/AssignCourseProvider/hooks/useAssignCourseDispatcher';
import Users from './Users';
import Communities from './Communities';
import { UserAssignmentType } from 'providers/AssignCourseProvider/contexts/AssignCourseContext';
import AssignmentsGuide from 'features/course-assignment/atomic/AssignmentsGuide';

interface TabPanelProps {
  children?: ReactNode;
  index: number;
  value: number;
}

function a11yProps(index: number) {
  return {
    id: `assignment-type-tab-${index}`,
    'aria-controls': `assignment-type-tabpanel-${index}`,
  };
}

const TabPanel = memo(function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`assignment-type-tabpanel-${index}`}
      aria-labelledby={`assignment-type-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
});

const TabsComponent = memo(function TabsComponent({
  userAssignmentType,
  handleChangeTab,
  organizationId,
  isOrganizationManager,
}: {
  userAssignmentType: number;
  handleChangeTab: (event: React.SyntheticEvent, value: number) => void;
  organizationId: string;
  isOrganizationManager: boolean;
}) {
  return (
    <>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={userAssignmentType}
          onChange={handleChangeTab}
          aria-label="assignment type tab"
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab label="Communities" {...a11yProps(0)} />
          <Tab label="Organization members" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <TabPanel value={userAssignmentType} index={0}>
        <Communities
          organizationId={organizationId}
          isOrganizationManager={isOrganizationManager}
        />
      </TabPanel>
      <TabPanel value={userAssignmentType} index={1}>
        <Users
          organizationId={organizationId}
          isOrganizationManager={isOrganizationManager}
        />
      </TabPanel>
    </>
  );
});

const UserAssignmentTypeStep = ({
  organizationId,
  isOrganizationManager,
}: {
  organizationId: string;
  isOrganizationManager: boolean;
}) => {
  const { assignCourseState, setAssignCourseState } =
    useAssignCourseDispatcher();
  const { userAssignmentType, organizationAssignmentId, name } =
    assignCourseState;
  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down('sm'));

  const changeName = useCallback(
    (name: string) => {
      setAssignCourseState((prevState: any) => ({
        ...prevState,
        name: name,
      }));
    },
    [setAssignCourseState]
  );

  const handleChangeTab = useCallback(
    (event: React.SyntheticEvent, newUserAssignmentTypeValue: number) => {
      setAssignCourseState((prevState: any) => ({
        ...prevState,
        userAssignmentType: newUserAssignmentTypeValue,
      }));
    },
    [setAssignCourseState]
  );

  return (
    <Grid
      container
      flexDirection="row"
      sx={{ p: 2 }}
      justifyContent="space-between"
    >
      <Grid container columnSpacing={1}>
        <Grid item xs={12} mb={1}>
          <Typography variant="h3">
            Create a name for your assignment
          </Typography>
        </Grid>
        <Grid item xs={12} mb={1}>
          <Box mb={matchDownSM ? 0 : 2}>
            <TextField
              sx={{ width: '100%' }}
              name="address"
              label="Assignment name"
              margin="dense"
              value={name}
              onChange={(e) => changeName(e.target.value)}
            />
          </Box>
        </Grid>

        {!organizationAssignmentId && (
          <Grid item xs={12} mb={1}>
            <Typography
              variant="h4"
              sx={{
                fontFamily: 'Raleway',
                marginTop: '15px',
              }}
            >
              Select if your assignment is on users or communities
            </Typography>
          </Grid>
        )}

        <Box sx={{ width: '100%' }}>
          {!organizationAssignmentId ? (
            <TabsComponent
              userAssignmentType={userAssignmentType}
              handleChangeTab={handleChangeTab}
              organizationId={organizationId}
              isOrganizationManager={isOrganizationManager}
            />
          ) : userAssignmentType === UserAssignmentType.Group ? (
            <Communities
              organizationId={organizationId}
              isOrganizationManager={isOrganizationManager}
            />
          ) : (
            <Users
              organizationId={organizationId}
              isOrganizationManager={isOrganizationManager}
            />
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

export default UserAssignmentTypeStep;
