import { useSnackbar } from 'notistack';
import ManageCourseWrapper from 'components/courseBuilder/ManageCourseWrapper';
import { useCallService } from 'hooks';
import { useAuthState } from 'providers/AuthProvider/hooks/useAuthState';
import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import CoassembleType from 'types/CoassembleType';
import httpRoutes from 'utils/httpRoutes';
import CourseReview from '../dialogs/CourseReview';
import PageContainer from 'components/PageContainer';
import { SuccessIcon } from 'material-icons';
import { CoassembleStatus } from 'types/courseTypes/CoassembleStatus';
import Button from 'components/atomic/Button';
import { Fab, Tooltip } from '@mui/material';
import FeedbackIcon from '@mui/icons-material/Feedback';

const CoassembleCourse = () => {
  const {
    authState: {
      user: { id: userId },
      organization,
    },
  } = useAuthState();

  const { callService } = useCallService();
  const params = useParams();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const { externalCourseId } = params;

  const courseId = Number(externalCourseId);

  const [url, setUrl] = useState('');
  const [utrId, setUTRId] = useState('');
  const [courseCompleted, setCourseCompleted] = useState<boolean>(false);
  const [openFeedbackModal, setOpenFeedbackModal] = useState<boolean>(false);
  const [hasCourseFeedback, setHasCourseFeedback] = useState<boolean>(false);

  const handleCourseEvent = async (event: any) => {
    if (event.event === 'feedback') {
      setOpenFeedbackModal(true);
      return;
    }

    const values: CoassembleType = {
      externalCourseId: courseId,
      status: event.event,
      progress: event.data.progress,
      score:
        event.data.score && event.event === 'complete'
          ? event.data.score.percentage
          : 0,
    };

    if (values.status === CoassembleStatus.End) {
      setCourseCompleted(true);
    }

    await updateCourseProgress(values);
  };

  const updateCourseProgress = async (values: CoassembleType) => {
    await callService({
      resource: httpRoutes.courseBuilder.updateCourseProgress(values),
    });
  };

  const getCourseUrl = async () => {
    if (!courseId) {
      return;
    }

    const requestData: {
      courseId: number;
      userId: string;
      organizationId: string;
    } = {
      courseId: courseId,
      userId: userId,
      organizationId: organization.id,
    };

    const { response } = await callService({
      resource: httpRoutes.courseBuilder.getCourseUrl(requestData),
    });

    if (response) {
      const url = encodeURI(`${response}`);

      setUrl(url);
    }
  };

  const getCourseUTR = async () => {
    const { response } = await callService({
      resource: httpRoutes.courseBuilder.getCourseUTRId({
        externalCourseId: courseId,
        organizationId: organization.id,
      }),
    });

    if (response) {
      setUTRId(response);
    }
  };

  const getCourseFeedback = async () => {
    const { response } = await callService({
      resource: httpRoutes.courseBuilder.getCourseFeedback({
        utrId: utrId,
        externalCourseId: courseId.toString(),
      }),
    });

    if (response) {
      setHasCourseFeedback(response);
    }
  };

  const onSuccess = () => {
    navigate(`/organization/${organization.id}/dashboard`);
    enqueueSnackbar('The feedback has been sent!', {
      variant: 'success',
      anchorOrigin: {
        horizontal: 'right',
        vertical: 'top',
      },
    });
  };

  useEffect(() => {
    getCourseUTR();
    getCourseUrl();
    getCourseFeedback();
  }, [courseId, utrId]);

  if (url === '') {
    return <div>Loading...</div>;
  }

  return (
    <>
      {openFeedbackModal ? (
        <PageContainer
          title="You're all done!"
          icon={<SuccessIcon sx={{ fontSize: '24px' }} />}
        >
          <CourseReview utrId={utrId} isModal={false} onSuccess={onSuccess} />
        </PageContainer>
      ) : (
        <>
          <ManageCourseWrapper
            coassembleURL={url}
            handleCourseEvent={handleCourseEvent}
          />
          {courseCompleted && !hasCourseFeedback && (
            <Button
              onClick={() => setOpenFeedbackModal(true)}
              sx={{
                backgroundColor: 'primary.main',
                color: 'white',
                '&:hover': {
                  backgroundColor: 'primary.dark',
                },
              }}
            >
              Provide Feedback on this Course
            </Button>
          )}
          {courseCompleted && hasCourseFeedback && (
            <Tooltip title="Provide Feedback on this Course" placement="left">
              <Fab
                onClick={() => setOpenFeedbackModal(true)}
                sx={{
                  position: 'fixed',
                  top: 80,
                  right: 16,
                  backgroundColor: 'primary.main',
                  color: 'white',
                  '&:hover': {
                    backgroundColor: 'primary.dark',
                  },
                  zIndex: 1100,
                }}
                size="medium"
              >
                <FeedbackIcon />
              </Fab>
            </Tooltip>
          )}
        </>
      )}
    </>
  );
};

export default CoassembleCourse;
