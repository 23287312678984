import { FC } from 'react';

import * as Yup from 'yup';

import { Alert, Grid, useMediaQuery, useTheme } from '@mui/material';
import SourceOption from './SourceOption';
import { useFormContext } from 'react-hook-form';
import CustomRadioFormField from 'components/formFields/CustomRadioFormField';

type CourseSourceProps = {
  isEdit?: any;
};

export const secondValidationStep = Yup.object().shape({
  source: Yup.string().required('Source option is required'),
});

const CourseSource: FC<CourseSourceProps> = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const theme = useTheme();
  const matchDownLG = useMediaQuery(theme.breakpoints.down('lg'));

  return (
    <Grid
      container
      flexDirection="row"
      sx={{ p: 2 }}
      justifyContent="space-between"
    >
      <Grid
        container
        flexDirection="row"
        justifyContent="center"
        columnSpacing={1}
      >
        <Alert severity="warning">
          Select a creation method carefuly, it is not possible to come back on
          the next step.
        </Alert>
        {matchDownLG && (
          <Alert
            severity="warning"
            style={{
              marginTop: '5px',
            }}
          >
            The SCORM file upload & AI course builder options are available on
            desktop only.
          </Alert>
        )}
        <Grid item xs={12} mt={1}>
          <CustomRadioFormField
            name="source"
            control={control}
            row={false}
            errors={errors.source}
            options={[
              {
                label: (
                  <SourceOption
                    icon="ai"
                    title="Create With AI"
                    description="Give AI a prompt and we will generate a course based on your prompts."
                  />
                ),
                value: 'ai',
              },
              {
                label: (
                  <SourceOption
                    icon="document"
                    title="Create From A Document Using AI"
                    description="Use this method if you'd like to convert a DOCX, PDF, PPT file into a course."
                  />
                ),
                value: 'document',
              },
              {
                label: (
                  <SourceOption
                    icon="scratch"
                    title="Create From Scratch"
                    description="Know what you want and how to get there? Start here to create a fresh course."
                  />
                ),
                value: 'scratch',
              },
              {
                label: (
                  <>
                    <SourceOption
                      icon="scorm"
                      title="Upload SCORM File"
                      description="Use this method if you already have a course created and exported as a zip file."
                    />
                    <Alert severity="warning">
                      Only SCORM2004 4th edition files are allowed.
                    </Alert>
                  </>
                ),
                value: 'scorm',
              },
            ]}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CourseSource;
