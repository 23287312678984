import React from 'react';

import { Controller, FieldError } from 'react-hook-form';

import {Box, TextField} from '@mui/material';
import {SxProps} from '@mui/system';

interface TextFormFieldProps {
  name: string;
  placeholder?: string;
  control: any;
  errors?: FieldError;
  icon?: React.ReactElement;
  position?: 'start' | 'end';
  margin?: 'normal' | 'dense' | 'none';
  multiline?: boolean;
  rows?: number;
  disabled?: boolean;
  required?: boolean;
  sx?: SxProps;
  id?: string;
}

const defaultSx = {
  background: 'white',
  borderRadius: '30px',
  '& .MuiOutlinedInput-root': {
    '& :-webkit-autofill': {
      borderRadius: '30px !important',
    },
    '& fieldset': {
      borderRadius: '30px',
    },
    '&:hover fieldset': {
      borderColor: '#56C596',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#56C596',
    },
  },
};

const TextFormField = ({
  name,
  placeholder,
  control,
  errors,
  icon,
  position = 'start',
  margin = 'normal',
  multiline = false,
  rows = 1,
  disabled = false,
  required,
  sx,
  id
}: TextFormFieldProps) => {
  let inputProps = {};

  if (icon) {
    if (position === 'start') {
      inputProps = {
        startAdornment: icon,
      };
    }

    if (position === 'end') {
      inputProps = {
        endAdornment: icon,
      };
    }
  }

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <Box>
          <TextField
            {...field}
            required={required}
            sx={{...defaultSx, ...sx}}
            variant="outlined"
            placeholder={placeholder}
            fullWidth
            margin={margin}
            multiline={multiline}
            rows={rows}
            error={Boolean(errors)}
            InputProps={inputProps}
            disabled={disabled}
            id={id}
            
          />
          <Box sx={{ width: 'fit-content', color: '#d32f2f', fontWeight: 'bold', minHeight: '19px' }}
            id={`${id}-error`}
          >
            {errors ? errors.message : ''}
          </Box>
        </Box>
      )}
    />
  );
};

export default TextFormField;
