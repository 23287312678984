import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Papa from 'papaparse';
import fileSaver from 'file-saver';

import DataGrid, {
  ControlledDataGrid,
  DataGridModel,
  useDataGridState,
} from 'components/dataGrid/components/DataGrid';
import { DataGridColumnProps } from 'components/dataGrid/components/DataGridColumn';
import PageContainer from 'components/PageContainer';

import { FilterType } from 'components/filters/filterTypes';
import { FilterState } from 'components/filters/Filters';

import { useCallService } from 'hooks';

import httpRoutes from 'utils/httpRoutes';
import {
  formatNumber,
  formatPercentageSymbolWithPrecision,
} from 'utils/common';
import { Box, Skeleton, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

// set filter model
const filterModel: FilterType[] = [
  {
    id: 'name',
    type: 'text',
    label: 'Group Name',
  },
];

const loadingState = {
  courses: true,
  exporting: false,
};

const AdminGroupCourseReportGrid = () => {
  const theme = useTheme();
  const { state } = useDataGridState();
  const { callService } = useCallService();

  const [title, setTitle] = useState({ location: '', groupName: '' });
  const [titleLoading, setTitleLoading] = useState(true);
  const [loading, setLoading] = useState(loadingState);
  const [filters, setFilters] = useState<FilterState>(new Map());
  const [gridData, setGridData] = useState<DataGridModel>({
    rows: [],
    totalRows: 0,
  });

  const navigate = useNavigate();
  const params = useParams();

  const matchDownLG = useMediaQuery(theme.breakpoints.down('lg'));
  const matchDownXS = useMediaQuery(theme.breakpoints.down('sm'));

  const getGroup = async () => {
    try {
      if (params.id) {
        const { response } = await callService({
          resource: httpRoutes.groups.getOne(params.id),
        });

        if (response) {
          let location = '';
          if (response.city && response.state) {
            location = `${response.city},${response.state}`;
          }
          setTitle({ location, groupName: response.name });
        }
      }
    } finally {
      setTitleLoading(false);
    }
  };

  const getGroups = async () => {
    setLoading((prevState) => ({ ...prevState, courses: true }));
    try {
      if (params.id) {
        const { response } = await callService({
          resource:
            httpRoutes.reporting.groupCourseAssignment.getAdminGroupCourseReport(
              {
                groupId: params.id,
                params: { ...state },
                filters: filters,
              }
            ),
        });

        if (response) {
          setGridData({
            rows: response.items,
            totalRows: response.totalRows,
          });
        }
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading((prevState) => ({ ...prevState, courses: false }));
    }
  };

  const exportGroups = async () => {
    try {
      if (params.id) {
        setLoading((prevState) => ({ ...prevState, exporting: true }));
        const { response } = await callService({
          resource:
            httpRoutes.reporting.groupCourseAssignment.exportAdminGroupCourseReport(
              {
                groupId: params.id,
                params: { ...state },
                filters: filters,
              }
            ),
          successMessage: 'Export complete!',
        });

        if (response) {
          const formattedCsv = response.map((item: any) => {
            const {
              name,
              learnerCount,
              totalStarted,
              totalCompleted,
              averageScore,
              pastDueCount,
            } = item;

            const percentCompleted =
              learnerCount > 0 ? (totalCompleted / learnerCount) * 100 : 0;

            const percentCompletedLabel =
              formatPercentageSymbolWithPrecision(percentCompleted);

            return {
              'Course Name': name,
              Learners: learnerCount,
              'Total Started': totalStarted,
              'Total Completed': totalCompleted,
              'Past Due': pastDueCount,
              'Average Score': averageScore,
              'Percent Completed': percentCompletedLabel,
            };
          });

          const csv = Papa.unparse(formattedCsv);
          const csvData = new Blob([csv], { type: 'text/plain:charset=utf-8' });

          fileSaver.saveAs(
            csvData,
            `${title.groupName} - Communities Assignment Report.csv`
          );

          setLoading((prevState) => ({ ...prevState, exporting: false }));
        }
      }
    } catch (e) {
      console.log('Error');
    }
  };

  useEffect(() => {
    getGroups();
  }, [filters, state]);

  useEffect(() => {
    getGroup();
  }, [params]);

  // set columns
  const columns: DataGridColumnProps[] = [
    {
      headerName: 'Course Name',
      field: 'name',
      sortable: true,
      filterable: false,
      flex: 1,
    },
    {
      headerName: 'Learners',
      field: 'learnerCount',
      sortable: true,
      filterable: false,
      headerAlign: 'center',
      type: 'number',
      flex: 0.5,
      ColumnComponent: (params: any) => {
        return formatNumber(params.row.learnerCount);
      },
    },
    {
      headerName: 'Total Started',
      field: 'totalStarted',
      sortable: false,
      filterable: false,
      headerAlign: 'center',
      type: 'number',
      flex: 0.5,
      ColumnComponent: (params: any) => {
        return formatNumber(params.row.totalStarted);
      },
    },
    {
      headerName: 'Total Completed',
      field: 'totalCompleted',
      sortable: false,
      filterable: false,
      headerAlign: 'center',
      type: 'number',
      flex: 0.5,
      ColumnComponent: (params: any) => {
        return formatNumber(params.row.totalCompleted);
      },
    },
    {
      headerName: 'Past due',
      field: 'pastDueCount',
      sortable: true,
      filterable: false,
      headerAlign: 'center',
      type: 'number',
      flex: 0.5,
      ColumnComponent: (params: any) => {
        return formatNumber(params.row.pastDueCount);
      },
    },
    {
      headerName: 'Average Score',
      field: 'averageScore',
      sortable: false,
      filterable: false,
      headerAlign: 'center',
      align: 'right',
      flex: 0.5,
      ColumnComponent: (params: any) => {
        const { averageScore } = params.row;

        if (!params.row.averageScore) {
          return <>-</>;
        }

        const averageScoreLabel =
          formatPercentageSymbolWithPrecision(averageScore);

        return <>{averageScoreLabel}</>;
      },
    },
    {
      headerName: 'Percent Completed',
      field: 'test',
      sortable: false,
      filterable: false,
      headerAlign: 'center',
      align: 'right',
      flex: 0.5,
      ColumnComponent: (params: any) => {
        const { totalCompleted, learnerCount } = params.row;

        const percentCompleted =
          learnerCount > 0 ? (totalCompleted / learnerCount) * 100 : 0;

        const percentCompletedLabel =
          formatPercentageSymbolWithPrecision(percentCompleted);

        return <>{percentCompletedLabel}</>;
      },
    },
  ];

  return (
    <PageContainer>
      <Box sx={{ px: matchDownLG ? 0 : 4, gap: 1 }}>
        {titleLoading ? (
          <Skeleton variant="rectangular" height={70} width="50%" />
        ) : (
          <Box mb={2}>
            {title.location && (
              <Box
                sx={{
                  fontFamily: 'Raleway',
                  fontWeight: '500',
                  fontSize: matchDownXS ? '12px' : '24px',
                  lineHeight: '130%',
                  color: '#162738',
                }}
              >
                {title.location}
              </Box>
            )}
            <Box
              sx={{
                fontFamily: 'Raleway',
                fontWeight: 600,
                fontSize: matchDownXS ? '20px' : '40px',
                color: '#162738',
                lineHeight: '130%',
              }}
            >
              {title.groupName}
            </Box>
          </Box>
        )}

        <DataGrid
          rows={gridData.rows}
          columns={columns}
          totalRows={gridData.totalRows}
          filters={filterModel}
          handleFilterChange={setFilters}
          handleExport={exportGroups}
          loading={loading.courses}
          onRowClick={(row) => {
            navigate(
              `/admin/reports/group-course-assignment/${params.id}/course/${row.id}`
            );
          }}
        />
      </Box>
    </PageContainer>
  );
};

const AdminGroupCourseReport = () => (
  <ControlledDataGrid>
    <AdminGroupCourseReportGrid />
  </ControlledDataGrid>
);

export default AdminGroupCourseReport;
