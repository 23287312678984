/* eslint-disable react/jsx-key */
import { useState } from 'react';

import {
  CircularProgress,
  InputAdornment,
  TextField,
  Box,
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';

import { Controller, FieldError } from 'react-hook-form';

import { useDebouncedEffect } from 'hooks';
import { SxProps } from '@mui/system';
import Icon from '../../icons';

type AsyncSelectFormFieldProps = {
  name: string;
  control: any;
  placeholder?: string;
  errors?: FieldError;
  getOptions: (name: string) => any;
  disabled?: boolean;
  sx?: SxProps;
  required?: boolean;
  id?: string;
};

const defaultSx = {
  background: 'white',
  borderRadius: '30px',
  '& .MuiOutlinedInput-root': {
    '& :-webkit-autofill': {
      borderRadius: '30px !important',
    },
    '& fieldset': {
      borderRadius: '30px',
    },
    '&:hover fieldset': {
      borderColor: '#56C596',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#56C596',
    },
  },
};

const AsyncSelectFormField = ({
  name,
  control,
  placeholder,
  errors,
  getOptions,
  disabled,
  sx,
  required,
  id
}: AsyncSelectFormFieldProps) => {
  const [areOptionsLoading, setOptionsLoading] = useState(false);
  const [options, setOptions] = useState<any[]>([]);
  const [inputValue, setInputValue] = useState('');

  const fetchOptions = async () => {
    if (inputValue) {
      setOptionsLoading(true);

      const response = await getOptions(inputValue);

      setOptions(response);

      setOptionsLoading(false);
    }
  };

  useDebouncedEffect(fetchOptions, 200, [inputValue]);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange } }) => (
        <Autocomplete
          id="search"
          freeSolo
          fullWidth
          selectOnFocus
          disabled={disabled}
          loading={areOptionsLoading}
          inputValue={inputValue}
          onInputChange={(_event, newInputValue) => {
            if (newInputValue === '') {
              onChange(null);
            }
            setInputValue(newInputValue);
          }}
          onChange={(_event, value) => {
            if (value) {
              onChange(value.id);
            }
          }}
          options={options}
          getOptionLabel={(option) => option.label || option}
          renderInput={(params) => (
            <Box>
              <TextField
                required={required}
                {...params}
                sx={{ ...defaultSx, ...sx }}
                placeholder={placeholder}
                error={Boolean(errors)}
                InputProps={{
                  ...params.InputProps,
                  type: 'search',
                  startAdornment: (
                    <InputAdornment position="start">
                      <Icon name="lens" fill="#56C596" />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <>
                      {areOptionsLoading ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                    </>
                  ),  
                }}
                id={id}
              />
              <Box
                sx={{
                  width: 'fit-content',
                  color: '#d32f2f',
                  fontWeight: 'bold',
                  minHeight: '19px',
                }}
                id={`${id}-error`}
              >
                {errors ? errors.message : ''}
              </Box>
            </Box>
          )}
        />
      )}
    />
  );
};

export default AsyncSelectFormField;
