import React, { useState, useEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import Papa from 'papaparse';
import fileSaver from 'file-saver';

import DataGrid, {
  ControlledDataGrid,
  DataGridModel,
  useDataGridState,
} from 'components/dataGrid/components/DataGrid';

import { DataGridColumnProps } from 'components/dataGrid/components/DataGridColumn';
import LoadingButton from 'components/buttons/LoadingButton';
import PageContainer from 'components/PageContainer';

import { FilterType } from 'components/filters/filterTypes';
import { FilterState } from 'components/filters/Filters';

import { useCallService } from 'hooks';
import { useSnackbar } from 'notistack';

import httpRoutes from 'utils/httpRoutes';
import { Box, Skeleton, useMediaQuery } from '@mui/material';

import { useTheme } from '@mui/material/styles';
import ActivityStats from './partials/activityStats/ActivityStats';
import { calculateDateRange } from 'utils/date';
import { DateConfig } from 'utils/date';
import { addDateFilterOptions, getDates, setDateRangeFilters } from './utils';
import Overlay from 'features/courses/components/Overlay';

const loadingState = {
  learners: true,
  exporting: false,
  downloading: false,
  item: '',
  course: true,
  group: true,
};

// set columns
const columns: DataGridColumnProps[] = [
  {
    headerName: 'First Name',
    field: 'firstName',
    sortable: true,
    filterable: false,
    flex: 0.75,
  },
  {
    headerName: 'Last Name',
    field: 'lastName',
    sortable: true,
    filterable: false,
    flex: 0.75,
  },
  {
    headerName: 'Status',
    field: 'status',
    sortable: true,
    filterable: false,
    flex: 0.5,
  },
  {
    headerName: 'Completed',
    field: 'end',
    sortable: true,
    filterable: false,
    type: 'date',
    headerAlign: 'center',
    align: 'right',
    flex: 0.75,
    ColumnComponent: (params: any) => {
      if (params.row.end) {
        return <>{new Date(params.row.end).toLocaleString()}</>;
      }
      return <>--</>;
    },
  },
  {
    headerName: 'Passed',
    field: 'passed',
    sortable: true,
    filterable: false,
    type: 'boolean',
    headerAlign: 'center',
    align: 'right',
    flex: 0.25,
    ColumnComponent: (params: any) => {
      return <>{params.row.passed ? 'Yes' : 'No'}</>;
    },
  },
  {
    headerName: 'Score',
    field: 'score',
    sortable: true,
    filterable: false,
    headerAlign: 'center',
    align: 'right',
    flex: 0.25,
  },
];

let engagementlearnersRequestController: AbortController | undefined;

const CreatorCourseLearnerReportGrid = () => {
  const theme = useTheme();
  const searchParams = useSearchParams();
  const matchDownLG = useMediaQuery(theme.breakpoints.down('lg'));
  const matchUpXS = useMediaQuery(theme.breakpoints.down('sm'));

  const { state } = useDataGridState();
  const { callService } = useCallService();

  const { enqueueSnackbar } = useSnackbar();

  const [course, setCourse] = useState({
    name: '',
    category: '',
  });
  const [group, setGroup] = useState({
    name: '',
  });
  const [loading, setLoading] = useState(loadingState);
  const [filters, setFilters] = useState<FilterState>(new Map());
  const [gridData, setGridData] = useState<DataGridModel>({
    rows: [],
    totalRows: 0,
  });

  const [selectedStartDateFilter, setSelectedStartDateFilter] = useState('');
  const [selectedEndDateFilter, setSelectedEndDateFilter] = useState('');

  const startDateQS = searchParams[0].get('startDate');
  const endDateQS = searchParams[0].get('endDate');

  const acceptedDateRangeFilter = calculateDateRange(
    '-3 months' as DateConfig,
    '-1 day' as DateConfig
  );

  const params = useParams();

  const groupId = params.groupId || '';
  const courseId = params.id || '';

  // set filter model
  const filterModel: FilterType[] = [
    {
      id: 'name',
      type: 'text',
      label: 'Email or Name',
    },
  ];

  addDateFilterOptions(
    filterModel,
    '-3 months',
    '-1 day',
    startDateQS,
    endDateQS
  );

  const getCourse = async () => {
    try {
      if (courseId) {
        const { response } = await callService({
          resource: httpRoutes.courses.getCourseById(courseId),
        });

        if (response) {
          setCourse({
            name: response.name,
            category: response.courseCategory.name,
          });
        }
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading((prevState) => ({ ...prevState, course: false }));
    }
  };

  const getgroup = async () => {
    try {
      const { response } = await callService({
        resource: httpRoutes.groups.getOne(groupId),
      });
      if (response) {
        setGroup({
          name: response.name,
        });
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading((prevState) => ({ ...prevState, group: false }));
    }
  };

  const getLearners = async () => {
    setLoading((prevState) => ({ ...prevState, learners: true }));
    if (engagementlearnersRequestController) {
      engagementlearnersRequestController.abort();
    }

    try {
      engagementlearnersRequestController = new AbortController();
      if (groupId && courseId) {
        const { response } = await callService({
          resource:
            httpRoutes.reporting.course.getCreatorCourseGroupLearnersReport({
              controller: engagementlearnersRequestController,
              courseId,
              groupId,
              params: { ...state },
              filters: filters,
            }),
        });

        if (response) {
          setGridData({
            rows: response.items,
            totalRows: response.totalRows,
          });
          engagementlearnersRequestController = undefined;
          setLoading((prevState) => ({ ...prevState, learners: false }));
        }
      }
    } catch (e) {
      setLoading((prevState) => ({ ...prevState, learners: false }));
    }
  };

  const exportLearners = async () => {
    try {
      if (groupId && courseId) {
        setLoading((prevState) => ({ ...prevState, exporting: true }));
        const { response } = await callService({
          resource:
            httpRoutes.reporting.course.exportCreatorCourseGroupLearnersReport({
              courseId,
              groupId,
              params: { ...state },
              filters: filters,
            }),
          successMessage: 'Export complete!',
        });

        if (response) {
          const csv = Papa.unparse(response);
          const csvData = new Blob([csv], { type: 'text/plain:charset=utf-8' });

          fileSaver.saveAs(
            csvData,
            `${course.name} Engagement Learner Report.csv`
          );

          setLoading((prevState) => ({ ...prevState, exporting: false }));
        }
      }
    } catch (e) {
      console.log('Error');
    }
  };

  useEffect(() => {
    getLearners();
  }, [filters, state]);

  useEffect(() => {
    getCourse();
    setDateRangeFilters(filters, startDateQS, endDateQS);
    getgroup();
  }, [courseId]);

  const search = () => {
    setLoading((prevState) => ({ ...prevState, learners: true }));
  };

  const [selectedStartDate, selectedEndDate] = getDates(filters);

  return (
    <PageContainer>
      <Overlay
        open={
          loading.course ||
          loading.group ||
          loading.learners ||
          loading.exporting
        }
        zIndex={9999999}
      />
      <Box
        sx={{ px: matchDownLG ? 0 : 4 }}
        onKeyUpCapture={(e: any) => {
          if (e.keyCode === 13) {
            search();
            const [selectedStartDate, selectedEndDate] = getDates(filters);
            if (!selectedStartDate && !selectedEndDate) {
              setSelectedStartDateFilter('');
              setSelectedEndDateFilter('');
            }
          }
        }}
      >
        {loading.course ? (
          <Skeleton variant="rectangular" height={80} width="50%" />
        ) : (
          <Box sx={{ mb: 2 }}>
            <Box
              sx={{
                fontFamily: 'Raleway',
                fontWeight: '500',
                fontSize: matchUpXS ? '12px' : '24px',
                lineHeight: '130%',
                color: '#162738',
              }}
            >
              {course.category}
            </Box>
            <Box
              sx={{
                fontFamily: 'Raleway',
                fontWeight: 600,
                fontSize: matchUpXS ? '20px' : '40px',
                color: '#162738',
                lineHeight: '130%',
              }}
            >
              {course.name}
            </Box>
            <Box
              sx={{
                fontFamily: 'Raleway',
                fontWeight: 400,
                fontSize: '16px',
                color: '#162738',
                lineHeight: '130%',
              }}
            >
              {group.name}
            </Box>
          </Box>
        )}
        <ActivityStats
          courseId={courseId}
          groupId={groupId}
          startDate={selectedStartDate}
          endDate={selectedEndDate}
        />
        <DataGrid
          rows={gridData.rows}
          columns={columns}
          filters={filterModel}
          handleExport={exportLearners}
          handleFilterChange={setFilters}
          totalRows={gridData.totalRows}
          loading={loading.learners}
        />
      </Box>
    </PageContainer>
  );
};

const CreatorCourseLearnerReport = () => (
  <ControlledDataGrid>
    <CreatorCourseLearnerReportGrid />
  </ControlledDataGrid>
);

export default CreatorCourseLearnerReport;
