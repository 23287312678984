import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import Papa from 'papaparse';
import fileSaver from 'file-saver';

import { Box, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import DataGrid, {
  ControlledDataGrid,
  DataGridModel,
  useDataGridState,
} from 'components/dataGrid/components/DataGrid';

import { DataGridColumnProps } from 'components/dataGrid/components/DataGridColumn';
import PageContainer from 'components/PageContainer';
import DisplayText from 'components/DisplayText';
import { FilterType } from 'components/filters/filterTypes';
import { FilterState } from 'components/filters/Filters';

import { useCallService } from 'hooks';

import {
  formatNumber,
  formatPercentageSymbolWithPrecision,
} from 'utils/common';
import httpRoutes from 'utils/httpRoutes';

// set columns
const columns: DataGridColumnProps[] = [
  {
    headerName: 'Community Name',
    field: 'name',
    sortable: true,
    filterable: false,
    flex: 1,
  },
  {
    headerName: 'Assigned Learners',
    field: 'learnerCount',
    sortable: true,
    filterable: false,
    headerAlign: 'center',
    type: 'number',
    flex: 0.5,
    ColumnComponent: (params: any) => {
      return formatNumber(params.row.learnerCount);
    },
  },
  {
    headerName: 'Assigned Courses',
    field: 'assignedCourses',
    sortable: true,
    filterable: false,
    headerAlign: 'center',
    type: 'number',
    flex: 0.5,
    ColumnComponent: (params: any) => {
      return formatNumber(params.row.assignedCourses);
    },
  },
  {
    headerName: 'Total Started',
    field: 'startedCount',
    sortable: true,
    filterable: false,
    headerAlign: 'center',
    type: 'number',
    flex: 0.5,
    ColumnComponent: (params: any) => {
      return formatNumber(params.row.startedCount);
    },
  },
  {
    headerName: 'Total Completed',
    field: 'completedCount',
    sortable: true,
    filterable: false,
    headerAlign: 'center',
    type: 'number',
    flex: 0.5,
    ColumnComponent: (params: any) => {
      return formatNumber(params.row.completedCount);
    },
  },
  {
    headerName: 'Past due',
    field: 'pastDueCount',
    sortable: true,
    filterable: false,
    headerAlign: 'center',
    type: 'number',
    flex: 0.5,
    ColumnComponent: (params: any) => {
      return formatNumber(params.row.pastDueCount);
    },
  },
  // {
  //   headerName: 'Percent Started',
  //   field: 'percentStarted',
  //   sortable: true,
  //   filterable: false,
  //   headerAlign: 'center',
  //   align: 'right',
  //   flex: 0.5,
  //   ColumnComponent: (params: any) => {
  //     const { assignedCourses, startedCount } = params.row;
  //
  //     const percentStarted = (assignedCourses > 0) ? (startedCount / assignedCourses) * 100 : 0;
  //
  //     const percentStartedLabel = formatPercentageSymbolWithPrecision(percentStarted);
  //
  //     return <>{percentStartedLabel}</>;
  //   },
  // },
  // {
  //   headerName: 'Percent Completed',
  //   field: 'percentCompleted',
  //   sortable: true,
  //   filterable: false,
  //   headerAlign: 'center',
  //   align: 'right',
  //   flex: 0.5,
  //   ColumnComponent: (params: any) => {
  //     const { learnerCount, completedCount } = params.row;
  //     const percentCompleted =
  //       learnerCount > 0 ? (completedCount / learnerCount) * 100 : 0;

  //     const percentCompletedLabel =
  //       formatPercentageSymbolWithPrecision(percentCompleted);

  //     return <>{percentCompletedLabel}</>;
  //   },
  // },
];

// set filter model
const filterModel: FilterType[] = [
  {
    id: 'name',
    type: 'text',
    label: 'Group Name',
  },
];

const loadingState = {
  groups: true,
  exporting: false,
};

const FacilitatorGroupReportGrid = () => {
  const { state } = useDataGridState();
  const { callService } = useCallService();
  const theme = useTheme();

  const matchDownLG = useMediaQuery(theme.breakpoints.down('lg'));

  const [loading, setLoading] = useState(loadingState);
  const [filters, setFilters] = useState<FilterState>(new Map());
  const [gridData, setGridData] = useState<DataGridModel>({
    rows: [],
    totalRows: 0,
  });

  const navigate = useNavigate();

  useEffect(() => {
    getGroups();
  }, [filters, state]);
  const getGroups = async () => {
    setLoading((prevState) => ({ ...prevState, groups: true }));
    try {
      const { response } = await callService({
        resource:
          httpRoutes.reporting.groupCourseAssignment.getFacilitatorGroupReport({
            params: { ...state },
            filters: filters,
          }),
      });

      if (response) {
        setGridData({
          rows: response.items,
          totalRows: response.totalRows,
        });
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading((prevState) => ({ ...prevState, groups: false }));
    }
  };

  const exportGroups = async () => {
    try {
      setLoading((prevState) => ({ ...prevState, exporting: true }));
      const { response } = await callService({
        resource:
          httpRoutes.reporting.groupCourseAssignment.exportFacilitatorGroupReport(
            {
              params: { ...state },
              filters: filters,
            }
          ),
        successMessage: 'Export complete!',
      });

      if (response) {
        const formattedCsv = response.map((item: any) => {
          const {
            name,
            learnerCount,
            assignedCourses,
            startedCount,
            completedCount,
            pastDueCount,
            percentageCompleted,
          } = item;

          const percentCompletedLabel = formatPercentageSymbolWithPrecision(
            parseFloat(percentageCompleted)
          );

          return {
            'Community Name': name,
            Learners: learnerCount,
            'Assigned Courses': assignedCourses,
            'Total Started': startedCount,
            'Total Completed': completedCount,
            'Past Due': pastDueCount,
            // 'Percent Started': percentStartedLabel,
            'Percent Completed': percentCompletedLabel,
          };
        });

        const csv = Papa.unparse(formattedCsv);
        const csvData = new Blob([csv], { type: 'text/plain:charset=utf-8' });

        fileSaver.saveAs(csvData, 'Communities Assignment Report.csv');
      }
    } catch (e) {
      console.log('Error');
    } finally {
      setLoading((prevState) => ({ ...prevState, exporting: false }));
    }
  };

  return (
    <PageContainer>
      <Box sx={{ px: 0, gap: 1 }}>
        <Box sx={{ mb: 2 }}>
          <DisplayText
            type="h2"
            text="Communities Assignment Report"
            variant="semiBold"
            style={{ color: '#162738' }}
          />
        </Box>
        <DataGrid
          rows={gridData.rows}
          columns={columns}
          filters={filterModel}
          handleFilterChange={setFilters}
          totalRows={gridData.totalRows}
          handleExport={exportGroups}
          loading={loading.groups}
          onRowClick={(params) => {
            navigate(`/reports/group-course-assignment/${params.id}`);
          }}
        />
      </Box>
    </PageContainer>
  );
};

const FacilitatorGroupReport = () => (
  <ControlledDataGrid>
    <FacilitatorGroupReportGrid />
  </ControlledDataGrid>
);

export default FacilitatorGroupReport;
