import { useState } from 'react';

import { Box, Grid } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';

import EmailFormField from 'components/formFields/EmailFormField2';
import Button from 'components/buttons/Button';

import useCallService from 'hooks/useCallService';
import httpRoutes from 'utils/httpRoutes';
import { useNavigate } from 'react-router-dom';

export interface IForgotPasswordInputs {
  email: string;
}

const ForgotPassword = () => {
  const [isSubmitted, setiSSubmitted] = useState(false);
  const { callService } = useCallService();
  const navigate = useNavigate();

  const onSubmit = async (values: IForgotPasswordInputs) => {
    const { response } = await callService({
      resource: httpRoutes.authentication.forgotPasswordRequest(values),
    });

    if (response) {
      setiSSubmitted(true);
    }
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email('Must be a valid email')
      .max(255)
      .required('Email is required'),
  });

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<IForgotPasswordInputs>({
    defaultValues: {
      email: '',
    },
    shouldUnregister: false,
    resolver: yupResolver(validationSchema),
  });

  const goToSignup = () => navigate('/signup');
  const goToLogin = () => navigate('/login');

  return (
    <form style={{ width: '100%' }} onSubmit={handleSubmit(onSubmit)}>
      <Grid item xs={12}>
        <Box
          sx={{
            color: 'white',
            fontFamily: 'Raleway',
            fontWeight: '600',
            fontSize: '40px',
            lineHeight: '100%',
          }}
          id={isSubmitted ? 'forgot-password-title' : 'forgot-password-after-submit-title'}
        >
          {!isSubmitted
            ? 'Please enter your email below.'
            : 'Reset password email sent!'}
        </Box>
      </Grid>
      <Grid item xs={12} mb={4}>
        <Box
          sx={{
            color: 'white',
            fontFamily: 'Raleway',
            fontWeight: '600',
            fontSize: '20px',
            lineHeight: '32px',
            paddingTop: '20px',
          }}
          id={isSubmitted ? 'forgot-password-description' : 'forgot-password-after-submit-description'}
        >
          {!isSubmitted
            ? // eslint-disable-next-line quotes
              "We'll send you a link to set a new password."
            : 'Click the link in your email to reset your password'}
        </Box>
      </Grid>
      <Grid xs={12} item mb={3}>
        {!isSubmitted && (
          <EmailFormField
            name="email"
            control={control}
            placeholder="Email"
            errors={errors.email}
            margin="dense"
            id="forgot-password-email"
          />
        )}
      </Grid>
      <Grid mb={3} container>
        <Grid
          xs={6}
          item
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'start',
          }}
        >
          <Box
            style={{
              display: 'flex',
              alignItems: 'center',
              fontFamily: 'Raleway',
              fontWeight: '600',
              fontSize: '14px',
              lineHeight: '150%',
              color: '#56C596',
              cursor: 'pointer',
            }}
            onClick={goToLogin}
            id="forgot-password-back-to-login"
          >
            Back to login
          </Box>
        </Grid>
        <Grid
          xs={6}
          item
          sx={{ display: 'flex', alignItems: 'center', justifyContent: 'end' }}
        >
          <Box
            style={{
              display: 'flex',
              alignItems: 'center',
              fontFamily: 'Raleway',
              fontWeight: '600',
              fontSize: '14px',
              lineHeight: '150%',
              color: '#56C596',
              cursor: 'pointer',
            }}
            onClick={goToSignup}
            id="forgot-password-signup"
          >
            Don&apos;t have an account?
          </Box>
        </Grid>
      </Grid>
      <Grid item xs={12} mb={1}>
        {!isSubmitted && (
          <Button
            type="submit"
            label="Send email"
            disabled={isSubmitting}
            id="forgot-password-send-email-button"
            sx={{
              textTransform: 'uppercase',
              fontFamily: 'Raleway',
              fontWeight: '700',
              fontSize: '20px',
              lineHeight: '24px',
              background: 'linear-gradient(180deg, #AB58BB 0%, #651E7C 100%)',
            }}
          />
        )}
      </Grid>
    </form>
  );
};

export default ForgotPassword;
