import { createContext } from 'react';

export enum UserAssignmentType {
  Group = 0,
  Users = 1,
}

export enum AssignmentType {
  CurrentAndFuture = 'CurrentAndFuture',
  Future = 'Future',
  Current = 'Current',
}

export type CourseAssignmentDuration = {
  courseId: string;
  daysToComplete: number;
};

export interface AssignCourseState extends AssignCourseType {
  learners: number;
}

export type AssignCourseType = {
  organizationAssignmentId?: number;
  organizationId: string;
  name: string;
  groupsIds: string[];
  groupsIdsToDelete: string[];
  usersIds: string[];
  usersIdsToDelete: string[];
  assignmentType: AssignmentType;
  userAssignmentType: UserAssignmentType;
  courseAssignmentsDuration: CourseAssignmentDuration[];
  courseIdsToDelete: string[];
  userGroupAssignedIds: { groupId: string; userIds: string[] }[];
  userAssignmentIds: number[];
  fetching: boolean;
};

export const ASSIGN_COURSE_INITIAL_STATE: AssignCourseState = {
  name: '',
  organizationId: '',
  userAssignmentType: UserAssignmentType.Group,
  assignmentType: AssignmentType.Current,
  groupsIds: [],
  groupsIdsToDelete: [],
  usersIds: [],
  usersIdsToDelete: [],
  courseAssignmentsDuration: [],
  courseIdsToDelete: [],
  learners: 0,
  userGroupAssignedIds: [],
  userAssignmentIds: [],
  fetching: true,
};

const AssignCourseContext = createContext<{
  assignCourseState: AssignCourseState;
  setAssignCourseState: any;
  getOrganizationAssignment: any;
}>({
  assignCourseState: ASSIGN_COURSE_INITIAL_STATE,
  setAssignCourseState: () => {},
  getOrganizationAssignment: () => {},
});

export default AssignCourseContext;
