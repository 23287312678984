import React from 'react';
import { styled, SxProps } from '@mui/system';
import { useTheme } from '@mui/material';

const titleStyle = {
  fontFamily: 'Raleway',
  margin: '0px',
};

const bodyStyle = {
  fontFamily: 'Roboto',
  fontStyle: 'normal',
  margin: '0px',
};

const regular = {
  fontWeight: 'normal',
};

const semiBold = {
  fontWeight: 600,
};

const bold = {
  fontWeight: 'bold',
};

const medium = {
  fontWeight: 500,
};

const bodyLargeStyle = {
  fontSize: 18,
  lineHeight: '22px',
};

const bodyMediumStyle = {
  fontSize: 16,
};

const bodySmallStyle = {
  fontSize: 14,
  lineHeight: '24px',
};

const bodyXSmallStyle = {
  fontSize: 10,
  lineHeight: '14px',
};

interface textVariantDescription {
  renderElement: keyof JSX.IntrinsicElements;
  variants: Partial<Record<TextVariants, SxProps>>;
}

const variantsDescription: Record<TextTypes, textVariantDescription> = {
  h1: {
    renderElement: 'h1',
    variants: {
      regular: {
        ...titleStyle,
        fontWeight: 600,
        fontSize: 60,
        lineHeight: '72px',
      },
    },
  },
  h2: {
    renderElement: 'h2',
    variants: {
      regular: {
        ...titleStyle,
        fontWeight: 'normal',
        fontSize: 50,
        lineHeight: '60px',
      },
      semiBold: {
        ...titleStyle,
        fontWeight: 600,
        fontSize: '40px',
        lineHeight: '56px',
      },
    },
  },
  h3: {
    renderElement: 'h3',
    variants: {
      regular: {
        ...titleStyle,
        fontWeight: 'normal',
        fontSize: 35,
        lineHeight: '42px',
      },
    },
  },
  h4: {
    renderElement: 'h4',
    variants: {
      regular: {
        ...titleStyle,
        fontWeight: 600,
        fontSize: 30,
        lineHeight: '38px',
      },
    },
  },
  h5: {
    renderElement: 'h5',
    variants: {
      regular: {
        ...titleStyle,
        fontWeight: 600,
        fontSize: 25,
        lineHeight: '30px',
      },
    },
  },
  h6: {
    renderElement: 'h6',
    variants: {
      regular: {
        ...titleStyle,
        fontWeight: 600,
        fontSize: 20,
        lineHeight: '27px',
      },
    },
  },
  sh1: {
    renderElement: 'p',
    variants: {
      semiBold: {
        ...titleStyle,
        fontSize: 24,
        lineHeight: '32px',
        ...semiBold,
      },
    },
  },
  sh2: {
    renderElement: 'p',
    variants: {
      medium: {
        ...titleStyle,
        fontSize: 16,
        lineHeight: '24px',
        ...medium,
      },
      bold: {
        ...titleStyle,
        fontSize: 16,
        lineHeight: '24px',
        ...bold,
      },
    },
  },
  bodyLarge: {
    renderElement: 'p',
    variants: {
      regular: {
        ...bodyStyle,
        ...bodyLargeStyle,
        ...regular,
      },
      semiBold: {
        ...bodyStyle,
        ...bodyLargeStyle,
        ...semiBold,
      },
      bold: {
        ...bodyStyle,
        ...bodyLargeStyle,
        ...bold,
      },
    },
  },
  bodyMedium: {
    renderElement: 'p',
    variants: {
      regular: {
        ...bodyStyle,
        ...bodyMediumStyle,
        ...medium,
        lineHeight: '24px',
      },
      semiBold: {
        ...bodyStyle,
        ...bodyMediumStyle,
        ...semiBold,
        lineHeight: '24px',
      },
      bold: {
        ...bodyStyle,
        ...bodyMediumStyle,
        ...bold,
        lineHeight: '23px',
      },
    },
  },
  bodySmall: {
    renderElement: 'p',
    variants: {
      regular: {
        ...bodyStyle,
        ...bodySmallStyle,
        ...regular,
      },
      semiBold: {
        ...bodyStyle,
        ...bodySmallStyle,
        ...semiBold,
      },
    },
  },
  bodyXSmall: {
    renderElement: 'p',
    variants: {
      regular: {
        ...bodyStyle,
        ...bodyXSmallStyle,
        ...regular,
      },
      semiBold: {
        ...bodyStyle,
        ...bodyXSmallStyle,
        ...semiBold,
      },
    },
  },
  b2: {
    renderElement: 'p',
    variants: {
      regular: {
        ...bodyStyle,
        ...bodySmallStyle,
        ...regular,
      },
    },
  },
  b3: {
    renderElement: 'p',
    variants: {
      regular: {
        fontSize: '12px',
        lineHeight: '16px',
        ...regular,
      },
      medium: {
        ...bodyMediumStyle,
        lineHeight: '16px',
        ...medium,
      },
    },
  },
  b4: {
    renderElement: 'p',
    variants: {
      regular: {
        ...bodyStyle,
        ...bodyXSmallStyle,
        ...regular,
      },
      bold: {
        ...bodyStyle,
        ...bodyXSmallStyle,
        ...bold,
      },
    },
  },
};

type TextTypes =
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'h6'
  | 'sh1'
  | 'sh2'
  | 'bodyLarge'
  | 'bodyMedium'
  | 'bodySmall'
  | 'bodyXSmall'
  | 'b2'
  | 'b3'
  | 'b4';

type TextVariants = 'regular' | 'semiBold' | 'bold' | 'medium';

interface IDisplayTextProps {
  type: TextTypes;
  variant?: TextVariants;
  text: string;
  style?: Record<string, unknown>;
  id?: string;
}

const DisplayText: React.FC<IDisplayTextProps> = ({
  type = 'h1' as TextTypes,
  variant = 'regular' as TextVariants,
  text = '',
  style = {},
  id = '',
}) => {
  const theme = useTheme();
  const mappedType = variantsDescription[type];

  const variantStyles = mappedType.variants[variant as TextVariants];

  if (!variantStyles) {
    // eslint-disable-next-line no-console
    console.error(
      `DisplayText Component: No combination found for: ${type} and ${variant}`
    );
    return null;
  }

  const DisplayComponent = styled(mappedType.renderElement)(
    theme.unstable_sx(variantStyles)
  );

  return <DisplayComponent style={style} id={id}>{text}</DisplayComponent>;
};

export default DisplayText;
