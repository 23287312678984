import { useTheme } from '@mui/material/styles';
import { Box, Button, Grid, Stack, Typography } from '@mui/material';
import _ from 'lodash';

import { WarningAmberOutlinedIcon } from 'material-icons';

import { useDialogDispatcher } from 'providers/DialogProvider/hooks/useDialogDispatcher';
import { useCallService } from 'hooks';
import httpRoutes from 'utils/httpRoutes';
import LinearProgressWithLabel from 'components/atomic/LinearProgressWithLabel';
import { useEffect, useState } from 'react';
import { useAssignCourseDispatcher } from 'providers/AssignCourseProvider/hooks/useAssignCourseDispatcher';
import { useSnackbar } from 'notistack';
import { UserAssignmentRotateRequest } from '../interfaces';
import {
  ExecutionResult,
  ExecutionResultStatus,
  PromiseManagerFactory,
} from 'utils/promiseManager';
import { delay } from 'utils/common';
import { PromiseAdapter } from 'utils/promiseManager';
import { IPromiseManager } from 'utils/promiseManager';
import { IPromiseManagerFactory } from 'utils/promiseManager/interfaces/IPromiseManagerFactory';
import {
  AssignCourseState,
  UserAssignmentType,
} from 'providers/AssignCourseProvider/contexts/AssignCourseContext';

let promiseManager: IPromiseManager;

const DeleteAssignment = ({
  organizationAssignmentId,
  onSuccess,
  name,
}: {
  organizationAssignmentId: number;
  onSuccess: VoidFunction;
  name: string;
}) => {
  const theme = useTheme();

  const { hideDialog } = useDialogDispatcher();
  const { callService } = useCallService();
  const { enqueueSnackbar } = useSnackbar();
  const { assignCourseState, getOrganizationAssignment } =
    useAssignCourseDispatcher();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [progress, setProgress] = useState({
    label: '',
    value: 0,
    requests: 0,
    responses: 0,
  });

  useEffect(() => {
    const promiseManagerFactory: IPromiseManagerFactory =
      new PromiseManagerFactory();
    promiseManager = promiseManagerFactory.create();
  }, []);

  useEffect(() => {
    if (organizationAssignmentId) {
      getOrganizationAssignment(organizationAssignmentId);
    }
  }, []);

  const onSubmit = async () => {
    setIsSubmitting(true);
    try {
      if (!assignCourseState.fetching) {
        const { response } = await callService({
          resource: httpRoutes.assignCourse.remove(organizationAssignmentId),
          successMessage: `${name} removed successfully!`,
        });

        if (response) {
          if (
            (assignCourseState.courseAssignmentsDuration.length >= 5 &&
              assignCourseState.learners > 50) ||
            assignCourseState.learners >= 100
          ) {
            enqueueSnackbar(
              'This process could take some time because it is necessary to process a high volume of data, please be patient while we crunch the numbers.',
              {
                variant: 'info',
                anchorOrigin: {
                  horizontal: 'right',
                  vertical: 'top',
                },
              }
            );
          }

          await rotateUserAssignments(assignCourseState);

          onSuccess();
          hideDialog();
        }
      }
    } catch (error: any) {
      console.error(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const rotateUserAssignments = async (
    assignCourseState: AssignCourseState
  ) => {
    let userAssignmentIdsRotated: number[] = [];

    setProgress({
      label: 'Removing course assignments:',
      value: 0,
      requests: 0,
      responses: 0,
    });

    const userAssignmentRequests: UserAssignmentRotateRequest[] =
      buildUserAssignmentRotateRequestsByEntitiesToDelete(assignCourseState);

    const userAssignmentRotateRequests = buildRotateUserAssignmentRequests([
      ...new Set(userAssignmentRequests),
    ]);

    userAssignmentIdsRotated = await executeRotateUserAssignments(
      userAssignmentRotateRequests
    );

    const userAssignmentIdsToDelete = assignCourseState.userAssignmentIds
      .filter((id) => !userAssignmentIdsRotated.includes(id))
      .flat();

    if (userAssignmentIdsToDelete.length > 0) {
      await removeUserAssignments(userAssignmentIdsToDelete);
    }
  };

  const buildUserAssignmentRotateRequestsByEntitiesToDelete = (
    assignCourseState: AssignCourseState
  ) => {
    const courseIds = assignCourseState.courseAssignmentsDuration.map(
      (x) => x.courseId
    );

    // Get userIds based on userAssignmentType
    const usersIds =
      assignCourseState.userAssignmentType === UserAssignmentType.Group
        ? [
            ...new Set(
              assignCourseState.userGroupAssignedIds
                .map((assignment) => assignment.userIds)
                .flat()
            ),
          ]
        : assignCourseState.usersIds;

    return buildUserAssignmentRotateRequest(
      assignCourseState.organizationId,
      courseIds,
      usersIds
    );
  };

  const buildUserAssignmentRotateRequest = (
    organizationId: string,
    courseIds: string[],
    usersIds: string[]
  ) => {
    const userAssignmentRotateRequests: UserAssignmentRotateRequest[] = [];

    for (const userId of usersIds) {
      for (const courseId of courseIds) {
        userAssignmentRotateRequests.push({
          organizationId,
          courseId,
          userId,
        });
      }
    }

    return userAssignmentRotateRequests;
  };

  const buildRotateUserAssignmentRequests = (
    userAssignmentRequests: UserAssignmentRotateRequest[],
    requestLength = 10
  ): UserAssignmentRotateRequest[][] => {
    const userAssignmentRotateRequests: UserAssignmentRotateRequest[][] = [];

    const userAssignmentRequestsToRotate = _.cloneDeep(userAssignmentRequests);

    const initUserAssignmentRequests = () => {
      if (userAssignmentRequestsToRotate.length === 0) {
        return;
      }

      const getUserAssignmentRequests = (
        userAssignmentRequestsToRotate: UserAssignmentRotateRequest[]
      ) => {
        let userAssignmentRequests = [];
        if (userAssignmentRequestsToRotate.length >= requestLength) {
          userAssignmentRequests = userAssignmentRequestsToRotate.splice(
            0,
            requestLength
          );
        } else {
          userAssignmentRequests = userAssignmentRequestsToRotate.splice(
            0,
            userAssignmentRequestsToRotate.length
          );
        }

        userAssignmentRotateRequests.push(userAssignmentRequests);

        if (userAssignmentRequestsToRotate.length > 0) {
          getUserAssignmentRequests(userAssignmentRequestsToRotate);
        }
      };

      getUserAssignmentRequests(userAssignmentRequestsToRotate);

      initUserAssignmentRequests();
    };

    initUserAssignmentRequests();
    setProgress((prevState: any) => ({
      ...prevState,
      requests: userAssignmentRotateRequests.length,
    }));

    return userAssignmentRotateRequests;
  };

  const executeRotateUserAssignments = async (
    userAssignmentRotateRequests: UserAssignmentRotateRequest[][]
  ): Promise<number[]> => {
    const promises: PromiseAdapter[] = userAssignmentRotateRequests.map(
      (userAssignmentRotateRequest) =>
        new PromiseAdapter(
          executeRotateUserAssignmentRequest,
          [userAssignmentRotateRequest],
          this
        )
    );

    if (promises.length === 0) {
      return [];
    }

    const executionResult: ExecutionResult<any> =
      await promiseManager.getPromisesResult<any>(promises, 20);

    setProgress((prevState: any) => ({ ...prevState, value: 100 }));
    await delay(100);

    if (executionResult.status === ExecutionResultStatus.Failed) {
      throw executionResult.errors;
    }

    const userAssignmentIdsRotated: number[] = executionResult.results.flat();

    return userAssignmentIdsRotated;
  };

  const executeRotateUserAssignmentRequest = async (
    userAssignmentRotateRequests: UserAssignmentRotateRequest[]
  ) => {
    try {
      const { response, error } = await callService({
        resource: httpRoutes.assignCourse.rotateUserCourseAssignments(
          userAssignmentRotateRequests
        ),
      });

      if (response) {
        calculateProgress();
        return response;
      }

      if (error) {
        throw error;
      }
    } catch (error: any) {
      console.error(error);
      throw error;
    }
  };

  const removeUserAssignments = async (userAssignmentIdsToDelete: number[]) => {
    try {
      const { response, error } = await callService({
        resource: httpRoutes.assignCourse.deleteUserAssignments(
          userAssignmentIdsToDelete
        ),
      });

      if (response) {
        calculateProgress();
        return response;
      }

      if (error) {
        throw error;
      }
    } catch (error: any) {
      console.error(error);
      throw error;
    }
  };

  const calculateProgress = () => {
    const progressPercentage = (responses: number, requests: number) =>
      Math.ceil((responses / requests) * 100);

    setProgress((prevState: any) => ({
      ...prevState,
      value: progressPercentage(prevState.responses + 1, prevState.requests),
      responses: prevState.responses + 1,
    }));
  };

  return (
    <Stack>
      <WarningAmberOutlinedIcon
        sx={{
          fontSize: '160px',
          color: theme.palette.warning.dark,
          margin: '0 auto',
        }}
      />
      <Typography fontSize={24} fontWeight="bold" textAlign="center">
        {name}
      </Typography>
      <Typography variant="body1" align="center">
        Are you sure you want to remove {name}?
      </Typography>
      <Grid container justifyContent="space-between" mt={2}>
        <Button variant="text" onClick={hideDialog}>
          Cancel
        </Button>

        <Button
          variant="contained"
          color="error"
          onClick={onSubmit}
          disabled={isSubmitting || assignCourseState.fetching}
        >
          Yes, Remove
        </Button>
        {isSubmitting && (
          <Box sx={{ width: '100%' }}>
            <LinearProgressWithLabel
              label={progress.label}
              value={progress.value}
            />
          </Box>
        )}
      </Grid>
    </Stack>
  );
};

export default DeleteAssignment;
