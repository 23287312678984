import React, { useState, useEffect } from 'react';

import { Box, Grid, Button } from '@mui/material';
import { SaveIcon } from 'material-icons';
import { LoadingButton } from '@mui/lab';

import Summary from './steps/Summary';
import Stepper from '../../../components/Stepper';

import { useDialogDispatcher } from 'providers/DialogProvider/hooks/useDialogDispatcher';
import { useMultistepForm } from 'hooks/useMultistepForm';
import { useAssignCourseDispatcher } from 'providers/AssignCourseProvider/hooks/useAssignCourseDispatcher';
import {
  AssignCourseType,
  UserAssignmentType,
} from 'providers/AssignCourseProvider/contexts/AssignCourseContext';
import CourseList from './steps/CourseList';
import UserAssignmentTypeStep from './steps/UserAssignmentTypeStep';
import AssignmentTypeStep from './steps/AssignmentTypeStep';
import { useLiveChatWidgetDispatcher } from 'providers/LiveChatWidgetProvider/hooks/useLiveChatWidgetDispatcher';
import AssignmentsGuide from '../atomic/AssignmentsGuide';

export interface AssignCourseFormInput extends AssignCourseType {
  organizationId: string;
}

type Step = {
  component: React.ReactNode;
  title: string;
};

const MultiStepForm = ({
  organizationId,
  isOrganizationManager,
  isSubmitting,
  onSubmit,
}: {
  organizationId: string;
  isOrganizationManager: boolean;
  isSubmitting: boolean;
  onSubmit: (values: AssignCourseFormInput) => void;
}) => {
  const { assignCourseState } = useAssignCourseDispatcher();
  const {
    name,
    userAssignmentType,
    courseAssignmentsDuration,
    groupsIds,
    usersIds,
  } = assignCourseState;
  const { hideDialog } = useDialogDispatcher();
  const { setLiveChatWidgetState } = useLiveChatWidgetDispatcher();
  const [steps, setSteps] = useState<any>([]);
  const [completed, setCompleted] = useState<{
    [k: number]: boolean;
  }>({});

  useEffect(() => {
    const newSteps = [
      {
        component: <AssignmentsGuide />,
        title: 'User Guide',
      },
      {
        component: (
          <UserAssignmentTypeStep
            organizationId={organizationId}
            isOrganizationManager={isOrganizationManager}
          />
        ),
        title: 'Assignment',
      },
      {
        component: <CourseList />,
        title: 'Courses',
      },
      ...(userAssignmentType === UserAssignmentType.Group
        ? [
            {
              component: <AssignmentTypeStep />,
              title: 'Assignment Type',
            },
          ]
        : []),
      {
        component: <Summary />,
        title: 'Review & Save',
      },
    ];
    setSteps(newSteps);
  }, [isSubmitting, assignCourseState]);

  const stepsComponents = steps.map((step: Step) => step.component);

  const { step, isFirstStep, isLastStep, back, next, goTo, currentStepIndex } =
    useMultistepForm(stepsComponents);

  const handleMultiStepSubmit = async () => {
    if (!isLastStep) {
      setCompleted({ ...completed, [currentStepIndex]: true });
      next();
    } else {
      const body: AssignCourseFormInput = {
        ...assignCourseState,
        organizationId: organizationId,
      };

      onSubmit(body);
    }
  };

  const handleCancel = () => {
    setLiveChatWidgetState({ visibility: 'minimized' });
    hideDialog();
  };

  const stepsTitles = steps.map((step: Step) => step.title);

  return (
    <Box sx={{ width: '100%' }}>
      <Stepper
        nonLinear={true}
        alternativeLabel={true}
        steps={stepsTitles}
        currentStepIndex={currentStepIndex}
        goTo={goTo}
        completedSteps={completed}
      />
      {step}
      <Grid container justifyContent="space-between" columnSpacing={1}>
        <Button disabled={isSubmitting} variant="text" onClick={handleCancel}>
          Cancel
        </Button>
        <Box>
          {!isFirstStep && (
            <Button disabled={isSubmitting} variant="text" onClick={back}>
              Back
            </Button>
          )}
          <LoadingButton
            type="button"
            variant="contained"
            onClick={handleMultiStepSubmit}
            disabled={
              isSubmitting ||
              (currentStepIndex === 1 && name === '') ||
              (currentStepIndex === 1 &&
                groupsIds.length === 0 &&
                usersIds.length === 0) ||
              (currentStepIndex === 2 &&
                courseAssignmentsDuration.length === 0) ||
              (isLastStep &&
                (name === '' ||
                  (groupsIds.length === 0 && usersIds.length === 0) ||
                  courseAssignmentsDuration.length === 0))
            }
            loading={isSubmitting}
            loadingPosition="start"
            {...(isLastStep ? { startIcon: <SaveIcon /> } : {})}
          >
            {isLastStep ? 'Confirm' : 'Next'}
          </LoadingButton>
        </Box>
      </Grid>
    </Box>
  );
};

export default MultiStepForm;
