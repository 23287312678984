import { useMemo, useState } from 'react';
import { Stack } from '@mui/material';
import Filter from './components/Filter';
import { FiltersProps, FilterType } from './filterTypes';
import { isObject } from 'utils/common';

const getDefaultValuesForFilters = (filters: FilterType[]) => {
  return filters.reduce((acc, filter) => {
    return acc.set(filter.id, filter.initialValue);
  }, new Map());
};

const getExistingKey = (id: string, _filterState: FilterState) => {
  const existingKey = _filterState.get(id) || undefined;
  return existingKey;
};

export type FilterState = Map<string, any>;

const Filters = ({ filters, onFilterChange, children }: FiltersProps) => {
  const [activeFilters, setActiveFilters] = useState(
    getDefaultValuesForFilters(filters)
  );

  const setFilterValue = (id: string, value: any) => {
    const _filterState = new Map(activeFilters);
    const existingKey = getExistingKey(id, _filterState);
    if (existingKey && isObject(value) && !(value instanceof Date)) {
      _filterState.set(id, {
        ...existingKey,
        ...value,
      });
    } else {
      _filterState.set(id, value);
    }

    setActiveFilters(_filterState);
    onFilterChange(_filterState);
  };

  const [filterList] = useMemo(() => {
    // eslint-disable-next-line no-underscore-dangle
    const _filterList = (
      <Stack direction={{ xs: 'column', sm: 'column', md: 'row' }} spacing={1}>
        {filters.map((filter) => (
          <Filter
            key={filter.id}
            filter={filter}
            setFilterValue={setFilterValue}
            filterValue={activeFilters.get(filter.id)}
          />
        ))}
        {children}
      </Stack>
    );

    return [_filterList];
  }, [filters, activeFilters]);

  return filterList;
};

export default Filters;
