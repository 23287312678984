import * as React from 'react';
import { Tooltip, Typography, Box } from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

const AssignmentTooltip = () => {
  return (
    <Tooltip
      title={
        <Box
          sx={{
            maxWidth: 400,
            maxHeight: '80vh',
            overflowY: 'auto',
            p: 2,
            '&::-webkit-scrollbar': { width: '8px' },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: 'rgba(0,0,0,0.2)',
              borderRadius: '4px',
            },
          }}
        >
          <Typography variant="h6" align="center" gutterBottom>
            📘 How Assignments Work
          </Typography>

          <Typography variant="subtitle2" gutterBottom>
            🔝 Priority Rules (Who gets the course?)
          </Typography>
          <ul>
            <li>
              <strong>✅ Direct-to-User:</strong> Highest priority. Overrides
              all other assignment types. Ensures specific user targeting.
            </li>
            <li>
              <strong>🏠 Primary Community:</strong> Second priority. Applies
              when no direct assignment exists. Requires valid primary community
              association.
            </li>
            <li>
              <strong>🌐 Other Communities:</strong> Lowest priority. Used when
              no higher-priority assignments exist. Must belong to the
              user&apos;s secondary communities.
            </li>
          </ul>
          <Typography
            variant="body2"
            sx={{
              color: '#664D03',
              fontStyle: 'italic',
              mt: 1,
              mb: 2,
              backgroundColor: '#FFF3CD',
              p: 1,
              borderRadius: 1,
              border: '1px solid',
              borderColor: '#FFE69C',
              display: 'flex',
              alignItems: 'center',
              gap: 1,
            }}
          >
            ⚠️ Only one active assignment per user is maintained based on these
            priority rules.
          </Typography>

          <Typography variant="subtitle2" gutterBottom>
            👥 Community Assignment Rules
          </Typography>
          <ul>
            <li>Active learner status (not pending/manager/archived)</li>
            <li>Established primary community</li>
            <li>No prior course completion</li>
            <li>Valid bundle association</li>
            <li>No existing higher-priority assignment</li>
          </ul>

          <Typography variant="subtitle2" gutterBottom>
            📚 Bundle Validation
          </Typography>
          <ul>
            <li>Course must be included in community bundle</li>
            <li>System automatically skips invalid assignment</li>
            <li>Regularly validating course bundles is recommended</li>
          </ul>

          <Typography variant="subtitle2" gutterBottom>
            ⏳ How Due Dates Work
          </Typography>
          <Typography variant="body2">
            When you assign a course, you also choose how many days the learner
            has to complete it. The system calculates the due date by adding the
            number of days you set to the assignment’s creation date.
            <br />
            <br />
            <strong>📅 Example:</strong>
            <br />
            5-day window = Due in 5 days from date of assignment
          </Typography>
          <Typography
            variant="body2"
            sx={{
              color: '#055160',
              fontStyle: 'italic',
              mt: 1,
              mb: 2,
              backgroundColor: '#CFF4FC',
              p: 1,
              borderRadius: 1,
              border: '1px solid',
              borderColor: '#B6EFFB',
              display: 'flex',
              alignItems: 'center',
              gap: 1,
            }}
          >
            ℹ️ 5-day window = Due in 5 days from date of assignment
          </Typography>

          <Typography variant="subtitle2" gutterBottom>
            ⚖️ What if the same course is assigned twice?
          </Typography>
          <Typography variant="body2">
            If the learner receives the same course from two places with the
            same priority (e.g. two other communities), the system will:
            <br />✅ Choose the one with the earliest due date.
            <br />
            <br />
            <strong>🎯 Use this to your advantage:</strong> Shorter due dates
            will “win” if both assignments are equal in priority.
          </Typography>
          <Typography
            variant="body2"
            sx={{
              color: '#055160',
              fontStyle: 'italic',
              mt: 1,
              mb: 2,
              backgroundColor: '#CFF4FC',
              p: 1,
              borderRadius: 1,
              border: '1px solid',
              borderColor: '#B6EFFB',
              display: 'flex',
              alignItems: 'center',
              gap: 1,
            }}
          >
            ℹ️ Shorter due dates will &quot;win&quot; if both assignments are
            equal in priority.
          </Typography>

          <Typography variant="subtitle2" gutterBottom>
            ✏️ Editing Assignments
          </Typography>
          <ul>
            <li>
              <strong>Add a course:</strong> Replaces a lower-priority one.
              Ignored if a higher-priority one exists.
            </li>
            <li>
              <strong>Remove a course:</strong> Another assignment may become
              active. If not, the learner keeps access, but without a due date.
            </li>
            <li>
              <strong>Change due date:</strong> Applied only if it comes from a
              higher-priority source.
            </li>
          </ul>
        </Box>
      }
      arrow
      placement="right"
      enterTouchDelay={0}
      leaveTouchDelay={5000}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 1,
          backgroundColor: '#CFF4FC',
          p: 1,
          borderRadius: 1,
          border: '1px solid',
          borderColor: '#B6EFFB',
          color: '#055160',
        }}
      >
        <HelpOutlineIcon fontSize="small" sx={{ color: 'info.main' }} />
        <Typography>Do you remember how assignments work?</Typography>
      </Box>
    </Tooltip>
  );
};

export default AssignmentTooltip;
