import * as React from 'react';

import { Controller, FieldError } from 'react-hook-form';

import {
  TextField,
  InputAdornment,
  Box,
  ClickAwayListener,
  Tooltip,
} from '@mui/material';

import InputMask from 'react-input-mask';

import { BirthdayIcon, HelpRoundedIcon } from 'material-icons';
import { SxProps } from '@mui/system';

const StartAdornment = () => (
  <InputAdornment position="end">
    <BirthdayIcon sx={{ color: '#71AB8C' }} />
  </InputAdornment>
);

const EndAdornment = () => {
  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <Tooltip
        PopperProps={{
          disablePortal: true,
        }}
        onClose={handleTooltipClose}
        open={open}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        title="Date format: MM/DD/YYYY"
      >
        <InputAdornment position="end">
          <HelpRoundedIcon
            sx={{ color: '#71AB8C', cursor: 'pointer' }}
            fontSize="small"
            onClick={handleTooltipOpen}
          />
        </InputAdornment>
      </Tooltip>
    </ClickAwayListener>
  );
};

interface BirthdayFormFieldProps {
  name: string;
  placeholder?: string;
  control: any;
  errors?: any;
  margin?: 'normal' | 'dense' | 'none';
  required?: boolean;
  sx?: SxProps;
  id?: string;
}

const defaultSx = {
  background: 'white',
  marginTop: 2,
  borderRadius: '30px',
  '& .MuiOutlinedInput-root': {
    '& :-webkit-autofill': {
      borderRadius: '30px !important',
    },
    '& fieldset': {
      borderRadius: '30px',
    },
    '&:hover fieldset': {
      borderColor: '#56C596',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#56C596',
    },
  },
};

export default function BirthdayFormField({
  name,
  placeholder,
  control,
  errors,
  margin = 'normal',
  required,
  sx,
  id
}: BirthdayFormFieldProps) {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <InputMask
          {...field}
          mask="99/99/9999"
          value={field.value}
          maskChar=" "
          onChange={(e: {
            target: { value: React.SetStateAction<undefined> };
          }) => {
            field.onChange(e.target.value);
          }}
        >
          {() => (
            <Box>
              <TextField
                sx={{ ...defaultSx, ...sx }}
                required={required}
                placeholder={placeholder}
                margin={margin}
                fullWidth
                error={Boolean(errors)}
                InputProps={{
                  startAdornment: <StartAdornment />,
                  endAdornment: <EndAdornment />,
                }}
                id={id}
              />
              <Box
                sx={{
                  width: 'fit-content',
                  color: '#d32f2f',
                  fontWeight: 'bold',
                  minHeight: '19px',
                }}
                id={`${id}-error`}
              >
                {errors ? errors.message : ''}
              </Box>
            </Box>
          )}
        </InputMask>
      )}
    />
  );
}
