import httpRoutes from 'utils/httpRoutes';
import { useEffect, useState } from 'react';
import MultiStepForm, {
  AssignCourseFormInput,
} from '../../forms/MultiStepForm';
import { useCallService } from 'hooks';
import { useDialogDispatcher } from 'providers/DialogProvider/hooks/useDialogDispatcher';
import { useSnackbar } from 'notistack';
import { useAssignCourseDispatcher } from 'providers/AssignCourseProvider/hooks/useAssignCourseDispatcher';
import { UserAssignmentType } from 'providers/AssignCourseProvider/contexts/AssignCourseContext';
import {
  CourseAssignmentDuration,
  CreateGroupCourseAssignmentRequest,
  CreateUserAssignmentRequest,
  CreateUserCourseAssignmentRequest,
  DeleteCourseAssignmentEntitiesRequest,
  DeleteCourseAssignmentsRequest,
  UserAssignmentEmailRequest,
  UserAssignmentRotateRequest,
} from 'features/course-assignment/interfaces';
import {
  ChunkTypes,
  ExecutionResult,
  IPromiseManager,
  PromiseAdapter,
  PromiseManagerFactory,
} from 'utils/promiseManager';
import { IPromiseManagerFactory } from 'utils/promiseManager/interfaces/IPromiseManagerFactory';
import _ from 'lodash';
import { Box } from '@mui/material';
import { LinearProgressWithLabel } from 'components/atomic';
import { delay } from 'utils/common';
import { useLiveChatWidgetDispatcher } from 'providers/LiveChatWidgetProvider/hooks/useLiveChatWidgetDispatcher';

let promiseManager: IPromiseManager;

const AssignCourse = ({
  organizationId,
  isOrganizationManager,
  onSuccess,
  organizationAssignmentId,
}: {
  organizationId: string;
  isOrganizationManager: boolean;
  onSuccess: VoidFunction;
  organizationAssignmentId?: number;
}) => {
  const { hideDialog } = useDialogDispatcher();
  const { callService } = useCallService();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { assignCourseState, getOrganizationAssignment } =
    useAssignCourseDispatcher();
  const { learners } = assignCourseState;
  const { setLiveChatWidgetState } = useLiveChatWidgetDispatcher();
  const [progress, setProgress] = useState({
    label: '',
    value: 0,
    requests: 0,
    responses: 0,
  });

  useEffect(() => {
    const promiseManagerFactory: IPromiseManagerFactory =
      new PromiseManagerFactory();
    promiseManager = promiseManagerFactory.create();
  }, []);

  useEffect(() => {
    if (organizationAssignmentId) {
      getOrganizationAssignment(organizationAssignmentId);
    }
  }, []);

  const onSubmit = async (body: AssignCourseFormInput) => {
    setIsSubmitting(true);
    try {
      if (
        (body.courseAssignmentsDuration.length >= 5 && learners > 50) ||
        learners >= 100
      ) {
        enqueueSnackbar(
          'This process could take some time because it is necessary to process a high volume of data, please be patient while we crunch the numbers.',
          {
            variant: 'info',
            anchorOrigin: {
              horizontal: 'right',
              vertical: 'top',
            },
          }
        );
      }
      if (organizationAssignmentId) {
        await updateOrganizationAssignmentProcess(
          organizationAssignmentId,
          body
        );
      } else {
        await createOrganizationAssignmentProcess(body);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLiveChatWidgetState({ visibility: 'minimized' });
      setIsSubmitting(false);
    }
  };

  const createOrganizationAssignmentProcess = async (
    body: AssignCourseFormInput
  ) => {
    setProgress({
      label: `STEP 1 - Creating course assignment ${body.name}:`,
      value: 0,
      requests: 1,
      responses: 0,
    });

    const { response } = await callService({
      resource: httpRoutes.assignCourse.create(body),
    });

    if (response) {
      await executeOrganizationAssignmentProcess(false, response.id, body);
    }
  };

  const updateOrganizationAssignmentProcess = async (
    organizationAssignmentId: number,
    body: AssignCourseFormInput
  ) => {
    setProgress({
      label: `STEP 1 - Updating course assignment ${body.name}:`,
      value: 0,
      requests: 1,
      responses: 0,
    });

    const { response } = await callService({
      resource: httpRoutes.assignCourse.update(organizationAssignmentId, {
        name: body.name,
        organizationId: body.organizationId,
        assignmentType: body.assignmentType,
      }),
    });

    if (response) {
      await executeOrganizationAssignmentProcess(
        true,
        organizationAssignmentId,
        body
      );
    }
  };

  const executeOrganizationAssignmentProcess = async (
    isEditing: boolean,
    organizationAssignmentId: number,
    body: AssignCourseFormInput
  ) => {
    let step = 2;
    const operation = isEditing ? 'Updating' : 'Creating';
    const successMessage = isEditing ? 'updated' : 'created';

    calculateProgress();
    await delay(100);

    let groupCourseAssignmentIds: number[] = [];
    let userCourseAssignmentIds: number[] = [];

    if (body.userAssignmentType === UserAssignmentType.Group) {
      setProgress({
        label: `STEP ${step} - ${operation} group course assignments:`,
        value: 0,
        requests: 0,
        responses: 0,
      });

      const courseAssignmentRequests: CreateGroupCourseAssignmentRequest[] =
        buildGroupCourseAssignmentRequests(organizationAssignmentId, body);

      groupCourseAssignmentIds = await createCoursesAssignations(
        courseAssignmentRequests
      );

      step++;
    }

    if (body.userAssignmentType === UserAssignmentType.Users) {
      setProgress({
        label: `STEP ${step} - ${operation} user course assignments:`,
        value: 0,
        requests: 0,
        responses: 0,
      });

      const courseAssignmentRequests: CreateUserCourseAssignmentRequest[] =
        buildUserCourseAssignmentRequests(organizationAssignmentId, body);

      userCourseAssignmentIds = await createUserCourseAssignations(
        courseAssignmentRequests
      );

      step++;
    }

    if (
      groupCourseAssignmentIds.length > 0 ||
      userCourseAssignmentIds.length > 0
    ) {
      setProgress({
        label: `STEP ${step} - ${operation} user assignments:`,
        value: 0,
        requests: 0,
        responses: 0,
      });

      const createUserAssignmentRequests: CreateUserAssignmentRequest[] =
        buildUserAssignmentRequests(
          groupCourseAssignmentIds,
          userCourseAssignmentIds
        );
      const userIds = await createUserAssignments(createUserAssignmentRequests);

      step++;

      setProgress({
        label: `${
          isEditing ? `STEP ${step}` : 'FINAL STEP'
        } - Send notification emails to assigned users:`,
        value: 0,
        requests: 0,
        responses: 0,
      });

      const userAssignmentEmailRequests: UserAssignmentEmailRequest[] =
        buildUserAssignmentEmailRequest(userIds);
      await sendEmailsToUserAssignments(userAssignmentEmailRequests);

      step++;
    }

    if (isEditing) {
      await removeEntities(organizationAssignmentId, body, step);

      await rotateUserAssignments(body);
    }

    onOrganizationAssignmentSuccess(successMessage);
  };

  // Start rotate user assignments
  const rotateUserAssignments = async (body: AssignCourseFormInput) => {
    setProgress({
      label: 'Last STEP - cleaning up course assignments:',
      value: 0,
      requests: 0,
      responses: 0,
    });

    const userAssignmentRequests: UserAssignmentRotateRequest[] = [];

    if (body.courseIdsToDelete.length > 0) {
      userAssignmentRequests.push(
        ...buildUserAssignmentRotateRequestsByCourseIdsToDelete(body)
      );
    }

    if (body.groupsIdsToDelete.length > 0 || body.usersIdsToDelete.length > 0) {
      userAssignmentRequests.push(
        ...buildUserAssignmentRotateRequestsByEntitiesToDelete(body)
      );
    }

    const userAssignmentRotateRequests = buildRotateUserAssignmentRequests([
      ...new Set(userAssignmentRequests),
    ]);

    await executeRotateUserAssignments(userAssignmentRotateRequests);
  };

  const buildUserAssignmentRotateRequestsByCourseIdsToDelete = (
    body: AssignCourseFormInput
  ) => {
    const usersIds =
      body.userAssignmentType === UserAssignmentType.Group
        ? body.userGroupAssignedIds
            .map((assignment) => assignment.userIds)
            .flat()
        : [...body.usersIds, ...body.usersIdsToDelete];

    return buildUserAssignmentRotateRequest(
      body.organizationId,
      body.courseIdsToDelete,
      usersIds
    );
  };

  const buildUserAssignmentRotateRequestsByEntitiesToDelete = (
    body: AssignCourseFormInput
  ) => {
    const courseAssignmentIds = body.courseAssignmentsDuration.map(
      (x) => x.courseId
    );

    const courseIds = [...courseAssignmentIds, ...body.courseIdsToDelete];

    // Get userIds based on userAssignmentType
    const usersIds =
      body.userAssignmentType === UserAssignmentType.Group
        ? [
            ...new Set(
              body.userGroupAssignedIds
                .filter((assignment) =>
                  body.groupsIdsToDelete.includes(assignment.groupId)
                )
                .map((assignment) => assignment.userIds)
                .flat()
            ),
          ]
        : body.usersIdsToDelete;

    return buildUserAssignmentRotateRequest(
      body.organizationId,
      courseIds,
      usersIds
    );
  };

  const buildUserAssignmentRotateRequest = (
    organizationId: string,
    courseIds: string[],
    usersIds: string[]
  ) => {
    const userAssignmentRotateRequests: UserAssignmentRotateRequest[] = [];

    for (const userId of usersIds) {
      for (const courseId of courseIds) {
        userAssignmentRotateRequests.push({
          organizationId,
          courseId,
          userId,
        });
      }
    }

    return userAssignmentRotateRequests;
  };

  const buildRotateUserAssignmentRequests = (
    userAssignmentRequests: UserAssignmentRotateRequest[],
    requestLength = 10
  ) => {
    const userAssignmentRotateRequests: UserAssignmentRotateRequest[][] = [];

    const userAssignmentRequestsToRotate = _.cloneDeep(userAssignmentRequests);

    const initUserAssignmentRequests = () => {
      if (userAssignmentRequestsToRotate.length === 0) {
        return;
      }

      const getUserAssignmentRequests = (
        userAssignmentRequestsToRotate: UserAssignmentRotateRequest[]
      ) => {
        let userAssignmentRequests = [];
        if (userAssignmentRequestsToRotate.length >= requestLength) {
          userAssignmentRequests = userAssignmentRequestsToRotate.splice(
            0,
            requestLength
          );
        } else {
          userAssignmentRequests = userAssignmentRequestsToRotate.splice(
            0,
            userAssignmentRequestsToRotate.length
          );
        }

        userAssignmentRotateRequests.push(userAssignmentRequests);

        if (userAssignmentRequestsToRotate.length > 0) {
          getUserAssignmentRequests(userAssignmentRequestsToRotate);
        }
      };

      getUserAssignmentRequests(userAssignmentRequestsToRotate);

      initUserAssignmentRequests();
    };

    initUserAssignmentRequests();
    setProgress((prevState: any) => ({
      ...prevState,
      requests: userAssignmentRotateRequests.length,
    }));

    return userAssignmentRotateRequests;
  };

  const executeRotateUserAssignments = async (
    userAssignmentRotateRequests: UserAssignmentRotateRequest[][]
  ) => {
    const promises: PromiseAdapter[] = userAssignmentRotateRequests.map(
      (userAssignmentRotateRequest) =>
        new PromiseAdapter(
          executeRotateUserAssignmentRequest,
          [userAssignmentRotateRequest],
          this
        )
    );

    const executionResult: ExecutionResult<any> =
      await promiseManager.getPromisesResult<any>(promises, 20);

    const courseAssignmentIds: number[] = executionResult.results
      .flat()
      .filter((id) => id !== undefined);

    setProgress((prevState: any) => ({ ...prevState, value: 100 }));
    await delay(100);

    return courseAssignmentIds;
  };

  const executeRotateUserAssignmentRequest = async (
    userAssignmentRotateRequests: UserAssignmentRotateRequest[]
  ) => {
    try {
      const { response } = await callService({
        resource: httpRoutes.assignCourse.rotateUserCourseAssignments(
          userAssignmentRotateRequests
        ),
      });

      if (response) {
        calculateProgress();
        return response;
      }
    } catch (error) {
      console.error(error);
      throw error;
    }
  };
  // End rotate user assignments

  const removeEntities = async (
    organizationAssignmentId: number,
    body: AssignCourseFormInput,
    step: number
  ) => {
    if (body.courseIdsToDelete.length > 0) {
      step = await deleteCourseAssignments(
        organizationAssignmentId,
        body,
        step
      );
    }

    if (body.groupsIdsToDelete.length > 0) {
      step = await deleteCourseAssignmentEntities(
        organizationAssignmentId,
        body.groupsIdsToDelete,
        body.userAssignmentType,
        step
      );
    }

    if (body.usersIdsToDelete.length > 0) {
      step = await deleteCourseAssignmentEntities(
        organizationAssignmentId,
        body.usersIdsToDelete,
        body.userAssignmentType,
        step
      );
    }

    return step;
  };

  const deleteCourseAssignmentEntities = async (
    organizationAssignmentId: number,
    entityIds: string[],
    userAssignmentType: UserAssignmentType,
    step: number
  ) => {
    setProgress({
      label: `STEP ${step} - Removing ${
        userAssignmentType === UserAssignmentType.Group ? 'group' : 'user'
      } course assignments:`,
      value: 0,
      requests: 0,
      responses: 0,
    });

    const deleteCourseAssignmentEntitiesRequests: DeleteCourseAssignmentEntitiesRequest[] =
      buildDeleteCourseAssignmentEntitiesRequests(
        organizationAssignmentId,
        entityIds
      );

    await executeDeleteCourseAssignmentEntities(
      userAssignmentType,
      deleteCourseAssignmentEntitiesRequests
    );

    step++;

    return step;
  };

  const buildDeleteCourseAssignmentEntitiesRequests = (
    organizationAssignmentId: number,
    entityIds: string[],
    requestLength = 10
  ) => {
    const deleteCourseAssignmentEntitiesRequests: DeleteCourseAssignmentEntitiesRequest[] =
      [];

    const entityIdsToDelete = _.cloneDeep(entityIds);

    const initCourseAssignments = () => {
      if (entityIdsToDelete.length === 0) {
        return;
      }

      const getEntityIds = (entityIdsToDelete: string[]) => {
        let entityIds = [];
        if (entityIdsToDelete.length >= requestLength) {
          entityIds = entityIdsToDelete.splice(0, requestLength);
        } else {
          entityIds = entityIdsToDelete.splice(0, entityIdsToDelete.length);
        }

        const deleteCourseAssignmentEntitiesRequest: DeleteCourseAssignmentEntitiesRequest =
          {
            organizationAssignmentId,
            idsToDelete: entityIds,
          };

        deleteCourseAssignmentEntitiesRequests.push(
          deleteCourseAssignmentEntitiesRequest
        );

        if (entityIdsToDelete.length > 0) {
          getEntityIds(entityIdsToDelete);
        }
      };

      getEntityIds(entityIdsToDelete);

      initCourseAssignments();
    };

    initCourseAssignments();
    setProgress((prevState: any) => ({
      ...prevState,
      requests: deleteCourseAssignmentEntitiesRequests.length,
    }));

    return deleteCourseAssignmentEntitiesRequests;
  };

  const executeDeleteCourseAssignmentEntities = async (
    userAssignmentType: UserAssignmentType,
    deleteCourseAssignmentEntitiesRequests: DeleteCourseAssignmentEntitiesRequest[]
  ) => {
    const promises: PromiseAdapter[] =
      deleteCourseAssignmentEntitiesRequests.map(
        (deleteCourseAssignmentEntitiesRequest) =>
          new PromiseAdapter(
            userAssignmentType === UserAssignmentType.Group
              ? deleteGroupCourseAssignments
              : deleteUserCourseAssignments,
            [deleteCourseAssignmentEntitiesRequest],
            this
          )
      );

    await promiseManager.getPromisesResult<any>(promises, 20);

    setProgress((prevState: any) => ({ ...prevState, value: 100 }));
    await delay(100);
  };

  const deleteGroupCourseAssignments = async (
    deleteCourseAssignmentEntitiesRequest: DeleteCourseAssignmentEntitiesRequest
  ) => {
    try {
      const { response } = await callService({
        resource: httpRoutes.assignCourse.deleteGroupCourseAssignments(
          deleteCourseAssignmentEntitiesRequest.organizationAssignmentId,
          deleteCourseAssignmentEntitiesRequest.idsToDelete
        ),
      });

      if (response) {
        calculateProgress();
        return response;
      }
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const deleteUserCourseAssignments = async (
    deleteCourseAssignmentEntitiesRequest: DeleteCourseAssignmentEntitiesRequest
  ) => {
    try {
      const { response } = await callService({
        resource: httpRoutes.assignCourse.deleteUserCourseAssignments(
          deleteCourseAssignmentEntitiesRequest.organizationAssignmentId,
          deleteCourseAssignmentEntitiesRequest.idsToDelete
        ),
      });

      if (response) {
        calculateProgress();
        return response;
      }
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const deleteCourseAssignments = async (
    organizationAssignmentId: number,
    body: AssignCourseFormInput,
    step: number
  ): Promise<number> => {
    setProgress({
      label: `STEP ${step} - Removing course assignments:`,
      value: 0,
      requests: 0,
      responses: 0,
    });

    const deleteCourseAssignmentsRequests: DeleteCourseAssignmentsRequest[] =
      buildDeleteCourseAssignmentsRequests(organizationAssignmentId, body);

    await deleteCoursesAssignations(
      body.userAssignmentType,
      deleteCourseAssignmentsRequests
    );

    step++;

    return step;
  };

  const buildDeleteCourseAssignmentsRequests = (
    organizationAssignmentId: number,
    body: AssignCourseFormInput,
    requestLength = 10
  ) => {
    const deleteCourseAssignmentsRequests: DeleteCourseAssignmentsRequest[] =
      [];

    const courseIdsToDelete = _.cloneDeep(body.courseIdsToDelete);

    const initCourseAssignments = () => {
      if (courseIdsToDelete.length === 0) {
        return;
      }

      const getCourseIds = (courseIdsToDelete: string[]) => {
        let courseIds: string[] = [];
        if (courseIdsToDelete.length >= requestLength) {
          courseIds = courseIdsToDelete.splice(0, requestLength);
        } else {
          courseIds = courseIdsToDelete.splice(0, courseIdsToDelete.length);
        }

        const deleteCourseAssignmentsRequest: DeleteCourseAssignmentsRequest = {
          organizationAssignmentId,
          courseIds,
        };

        deleteCourseAssignmentsRequests.push(deleteCourseAssignmentsRequest);

        if (courseIdsToDelete.length > 0) {
          getCourseIds(courseIdsToDelete);
        }
      };

      getCourseIds(courseIdsToDelete);

      initCourseAssignments();
    };

    initCourseAssignments();
    setProgress((prevState: any) => ({
      ...prevState,
      requests: deleteCourseAssignmentsRequests.length,
    }));

    return deleteCourseAssignmentsRequests;
  };

  const deleteCoursesAssignations = async (
    userAssignmentType: UserAssignmentType,
    deleteCourseAssignmentsRequests: DeleteCourseAssignmentsRequest[]
  ) => {
    const promises: PromiseAdapter[] = deleteCourseAssignmentsRequests.map(
      (deleteCourseAssignmentsRequest) =>
        new PromiseAdapter(
          userAssignmentType === UserAssignmentType.Group
            ? deleteGroupCourseAssignmentsByCourseIds
            : deleteUserCourseAssignmentsByCourseIds,
          [deleteCourseAssignmentsRequest],
          this
        )
    );

    await promiseManager.getPromisesResult<any>(promises, 20);

    setProgress((prevState: any) => ({ ...prevState, value: 100 }));
    await delay(100);
  };

  const deleteGroupCourseAssignmentsByCourseIds = async (
    deleteCourseAssignmentsRequest: DeleteCourseAssignmentsRequest
  ) => {
    try {
      const { response } = await callService({
        resource:
          httpRoutes.assignCourse.deleteGroupCourseAssignmentsByCourseIds(
            deleteCourseAssignmentsRequest.organizationAssignmentId,
            deleteCourseAssignmentsRequest.courseIds
          ),
      });

      if (response) {
        calculateProgress();
        return response;
      }
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const deleteUserCourseAssignmentsByCourseIds = async (
    deleteCourseAssignmentsRequest: DeleteCourseAssignmentsRequest
  ) => {
    try {
      const { response } = await callService({
        resource:
          httpRoutes.assignCourse.deleteUserCourseAssignmentsByCourseIds(
            deleteCourseAssignmentsRequest.organizationAssignmentId,
            deleteCourseAssignmentsRequest.courseIds
          ),
      });

      if (response) {
        calculateProgress();
        return response;
      }
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const onOrganizationAssignmentSuccess = (successMessage: string) => {
    enqueueSnackbar(`Assigment ${successMessage} successfully!`, {
      variant: 'success',
      anchorOrigin: {
        horizontal: 'right',
        vertical: 'top',
      },
    });

    onSuccess();
    hideDialog();
  };

  const createCoursesAssignations = async (
    createGroupCourseAssignmentRequests: CreateGroupCourseAssignmentRequest[]
  ) => {
    const promises: PromiseAdapter[] = createGroupCourseAssignmentRequests.map(
      (createGroupCourseAssignmentRequest) =>
        new PromiseAdapter(
          createCoursesAssignation,
          [createGroupCourseAssignmentRequest],
          this
        )
    );

    const executionResult: ExecutionResult<any> =
      await promiseManager.getPromisesResult<any>(promises, 20);
    const groupCourseAssignmentIds: number[] = executionResult.results
      .flat()
      .map((x: any) => x.id)
      .filter((id) => id !== undefined);

    setProgress((prevState: any) => ({ ...prevState, value: 100 }));
    await delay(100);

    return groupCourseAssignmentIds;
  };

  const createCoursesAssignation = async (
    createGroupCourseAssignmentRequest: CreateGroupCourseAssignmentRequest
  ) => {
    try {
      const { response } = await callService({
        resource: httpRoutes.assignCourse.createGroupCourseAssignment(
          createGroupCourseAssignmentRequest
        ),
      });

      if (response) {
        calculateProgress();
        return response;
      }
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const buildGroupCourseAssignmentRequests = (
    organizationAssignmentId: number,
    body: AssignCourseFormInput,
    requestLength = 10
  ) => {
    const createGroupCourseAssignmentRequests: CreateGroupCourseAssignmentRequest[] =
      [];
    const courseAssignmentsDurationToSplice = _.cloneDeep(
      body.courseAssignmentsDuration
    );

    const initCourseAssignments = () => {
      if (courseAssignmentsDurationToSplice.length === 0) {
        return;
      }

      let courseAssignmentsDuration: CourseAssignmentDuration[] = [];
      if (courseAssignmentsDurationToSplice.length >= requestLength) {
        courseAssignmentsDuration = courseAssignmentsDurationToSplice.splice(
          0,
          requestLength
        );
      } else {
        courseAssignmentsDuration = courseAssignmentsDurationToSplice.splice(
          0,
          courseAssignmentsDurationToSplice.length
        );
      }

      const groupsIdsToBuild = _.cloneDeep(body.groupsIds);

      const getGroupIds = (groupsIdsToBuild: string[]) => {
        let groupsIds = [];
        if (groupsIdsToBuild.length >= requestLength) {
          groupsIds = groupsIdsToBuild.splice(0, requestLength);
        } else {
          groupsIds = groupsIdsToBuild.splice(0, groupsIdsToBuild.length);
        }
        const createGroupCourseAssignmentRequest: CreateGroupCourseAssignmentRequest =
          {
            organizationAssignmentId,
            groupsIds,
            courseAssignmentsDuration: courseAssignmentsDuration,
          };
        createGroupCourseAssignmentRequests.push(
          createGroupCourseAssignmentRequest
        );
        if (groupsIdsToBuild.length > 0) {
          getGroupIds(groupsIdsToBuild);
        }
      };

      getGroupIds(groupsIdsToBuild);

      initCourseAssignments();
    };

    initCourseAssignments();
    setProgress((prevState: any) => ({
      ...prevState,
      requests: createGroupCourseAssignmentRequests.length,
    }));

    return createGroupCourseAssignmentRequests;
  };

  const createUserCourseAssignations = async (
    createUserCourseAssignmentRequests: CreateUserCourseAssignmentRequest[]
  ) => {
    const promises: PromiseAdapter[] = createUserCourseAssignmentRequests.map(
      (createUserCourseAssignmentRequest) =>
        new PromiseAdapter(
          createUserCourseAssignation,
          [createUserCourseAssignmentRequest],
          this
        )
    );

    const executionResult: ExecutionResult<any> =
      await promiseManager.getPromisesResult<any>(promises, 20);
    const userCourseAssignmentIds: number[] = executionResult.results
      .flat()
      .map((x: any) => x.id)
      .filter((id) => id !== undefined);

    setProgress((prevState: any) => ({ ...prevState, value: 100 }));
    await delay(100);

    return userCourseAssignmentIds;
  };

  const createUserCourseAssignation = async (
    createUserCourseAssignmentRequest: CreateUserCourseAssignmentRequest
  ) => {
    try {
      const { response } = await callService({
        resource: httpRoutes.assignCourse.createUserCourseAssignment(
          createUserCourseAssignmentRequest
        ),
      });

      if (response) {
        calculateProgress();
        return response;
      }
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const buildUserCourseAssignmentRequests = (
    organizationAssignmentId: number,
    body: AssignCourseFormInput,
    requestLength = 10
  ) => {
    const createUserCourseAssignmentRequests: CreateUserCourseAssignmentRequest[] =
      [];
    const courseAssignmentsDurationToSplice = _.cloneDeep(
      body.courseAssignmentsDuration
    );

    const initCourseAssignments = () => {
      if (courseAssignmentsDurationToSplice.length === 0) {
        return;
      }

      let courseAssignmentsDuration: CourseAssignmentDuration[] = [];
      if (courseAssignmentsDurationToSplice.length >= requestLength) {
        courseAssignmentsDuration = courseAssignmentsDurationToSplice.splice(
          0,
          requestLength
        );
      } else {
        courseAssignmentsDuration = courseAssignmentsDurationToSplice.splice(
          0,
          courseAssignmentsDurationToSplice.length
        );
      }

      const usersIdsToBuild = _.cloneDeep(body.usersIds);

      const getUserIds = (userIdsToBuild: string[]) => {
        let usersIds = [];
        if (userIdsToBuild.length >= requestLength) {
          usersIds = userIdsToBuild.splice(0, requestLength);
        } else {
          usersIds = userIdsToBuild.splice(0, userIdsToBuild.length);
        }
        const createUserCourseAssignmentRequest: CreateUserCourseAssignmentRequest =
          {
            organizationAssignmentId,
            usersIds,
            courseAssignmentsDuration: courseAssignmentsDuration,
          };
        createUserCourseAssignmentRequests.push(
          createUserCourseAssignmentRequest
        );
        if (userIdsToBuild.length > 0) {
          getUserIds(userIdsToBuild);
        }
      };

      getUserIds(usersIdsToBuild);

      initCourseAssignments();
    };

    initCourseAssignments();
    setProgress((prevState: any) => ({
      ...prevState,
      requests: createUserCourseAssignmentRequests.length,
    }));

    return createUserCourseAssignmentRequests;
  };

  const createUserAssignments = async (
    createUserAssignmentRequests: CreateUserAssignmentRequest[]
  ) => {
    const promises: PromiseAdapter[] = createUserAssignmentRequests.map(
      (createUserAssignmentRequest) =>
        new PromiseAdapter(
          createUserAssignment,
          [createUserAssignmentRequest],
          this
        )
    );

    const executionResult: ExecutionResult<any> =
      await promiseManager.getPromisesResult<any>(promises, 10);
    const assignedUserIds: string[] = executionResult.results
      .flat()
      .filter((x: any) => x !== undefined)
      .map((x: any) => x.userId);

    const userIds: string[] = [...new Set(assignedUserIds)];
    setProgress((prevState: any) => ({ ...prevState, value: 100 }));
    await delay(100);

    return userIds;
  };

  const createUserAssignment = async (
    createUserAssignmentRequest: CreateUserAssignmentRequest
  ) => {
    try {
      const { response } = await callService({
        resource: httpRoutes.assignCourse.createUserAssignment(
          createUserAssignmentRequest
        ),
      });

      if (response) {
        calculateProgress();
        return response;
      }
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const buildUserAssignmentRequests = (
    groupCourseAssignmentIds: number[],
    userCourseAssignmentIds: number[],
    requestLength = 5
  ) => {
    const createUserAssignmentRequests: CreateUserAssignmentRequest[] = [];
    const groupCourseAssignmentIdsToSplice = _.cloneDeep(
      groupCourseAssignmentIds
    );

    const userCourseAssignmentIdsToSplice = _.cloneDeep(
      userCourseAssignmentIds
    );

    const initCourseAssignments = () => {
      if (
        groupCourseAssignmentIdsToSplice.length === 0 &&
        userCourseAssignmentIdsToSplice.length === 0
      ) {
        return;
      }

      let groupCourseAssignmentIds = [];
      let userCourseAssignmentIds = [];
      if (groupCourseAssignmentIdsToSplice.length >= requestLength) {
        groupCourseAssignmentIds = groupCourseAssignmentIdsToSplice.splice(
          0,
          requestLength
        );
      } else {
        groupCourseAssignmentIds = groupCourseAssignmentIdsToSplice.splice(
          0,
          groupCourseAssignmentIdsToSplice.length
        );
      }

      if (userCourseAssignmentIdsToSplice.length >= requestLength) {
        userCourseAssignmentIds = userCourseAssignmentIdsToSplice.splice(
          0,
          requestLength
        );
      } else {
        userCourseAssignmentIds = userCourseAssignmentIdsToSplice.splice(
          0,
          userCourseAssignmentIdsToSplice.length
        );
      }

      const createUserAssignmentRequest: CreateUserAssignmentRequest = {
        groupCourseAssignmentIds,
        userCourseAssignmentIds,
      };

      createUserAssignmentRequests.push(createUserAssignmentRequest);

      initCourseAssignments();
    };

    initCourseAssignments();
    setProgress((prevState: any) => ({
      ...prevState,
      requests: createUserAssignmentRequests.length,
    }));

    return createUserAssignmentRequests;
  };

  const sendEmailsToUserAssignments = async (
    userAssignmentEmailRequests: UserAssignmentEmailRequest[]
  ) => {
    const promises: PromiseAdapter[] = userAssignmentEmailRequests.map(
      (userAssignmentEmailRequest) =>
        new PromiseAdapter(
          sendEmailToUserAssignment,
          [userAssignmentEmailRequest],
          this
        )
    );

    const executionResult: ExecutionResult<any> =
      await promiseManager.getPromisesResult<any>(
        promises,
        5,
        ChunkTypes.Amount
      );
    const results: any[] = executionResult.results.flat();

    setProgress((prevState: any) => ({ ...prevState, value: 100 }));
    await delay(100);

    return results;
  };

  const sendEmailToUserAssignment = async (
    userAssignmentEmailRequest: UserAssignmentEmailRequest
  ) => {
    try {
      const { response } = await callService({
        resource: httpRoutes.assignCourse.sendEmailToUserAssignment(
          userAssignmentEmailRequest
        ),
      });

      await delay(100);

      if (response) {
        calculateProgress();
        return response;
      }
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const buildUserAssignmentEmailRequest = (userIds: string[]) => {
    const userAssignmentEmailRequests: UserAssignmentEmailRequest[] =
      userIds.map((userId) => {
        const userAssignmentEmailRequest: UserAssignmentEmailRequest = {
          userId,
        };
        return userAssignmentEmailRequest;
      });

    setProgress((prevState: any) => ({
      ...prevState,
      requests: userAssignmentEmailRequests.length,
    }));

    return userAssignmentEmailRequests;
  };

  const calculateProgress = () => {
    const progressPercentage = (responses: number, requests: number) =>
      Math.ceil((responses / requests) * 100);

    setProgress((prevState: any) => ({
      ...prevState,
      value: progressPercentage(prevState.responses + 1, prevState.requests),
      responses: prevState.responses + 1,
    }));
  };

  return (
    <>
      <MultiStepForm
        onSubmit={onSubmit}
        organizationId={organizationId}
        isOrganizationManager={isOrganizationManager}
        isSubmitting={isSubmitting}
      />

      {isSubmitting && (
        <Box sx={{ width: '100%' }}>
          <LinearProgressWithLabel
            label={progress.label}
            value={progress.value}
          />
        </Box>
      )}
    </>
  );
};

export default AssignCourse;
