import { useEffect } from 'react';
import { Box } from '@mui/material';

const ManageCourseWrapper = ({
  handleCourseEvent,
  coassembleURL,
}: {
  handleCourseEvent?: (event: any) => void;
  coassembleURL: string;
}) => {
  useEffect(() => {
    if (handleCourseEvent) {
      const eventListenerClosure = (message: any) => {
        if (message.origin === 'https://coassemble.com') {
          const data = JSON.parse(message.data);

          if (data.action === 'unitComplete' && data.completed) {
            handleCourseEvent({
              event: 'end',
              data: {
                progress: 100,
              },
            });
          } else {
            switch (data.type) {
              case 'course':
                handleCourseEvent(data);
                break;
              case 'back':
                // handleBack();
                // ignore for now
                break;
              case 'screen':
                if (data.event === 'complete') {
                  handleCourseEvent(data);
                }
                break;
              case 'intercom-snippet__ready':
                break;
              default:
                break;
            }
          }
        }
        return;
      };

      window.addEventListener('message', eventListenerClosure, true);

      return () => {
        window.removeEventListener('message', eventListenerClosure, true);
      };
    }
  }, []);

  return (
    <Box height="100%" width="100%">
      <iframe
        id="scormframe"
        height="100%"
        width="100%"
        style={{ border: 'none' }}
        src={coassembleURL}
      />
    </Box>
  );
};

export default ManageCourseWrapper;
