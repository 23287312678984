import { FolderSharedOutlinedIcon } from 'material-icons';
import { LinkProps } from 'layout/MainLayout/LeftNavigationDrawer/types';
import { isProdOrDev } from 'utils/environment';

export const organizationManagementLinks: LinkProps[] = [
  {
    label: 'Organizations',
    link: '/admin/organizations',
    icon: 'organizations',
    isAdmin: true,
    id: 'organizations',
  },
  {
    label: 'Communities',
    link: '/admin/communities',
    icon: 'communities',
    isAdmin: true,
    id: 'communities',
  },
  {
    label: 'User Management',
    link: '/admin/user-management',
    icon: 'user-management',
    isAdmin: true,
    id: 'admin-user-management',
  },
];

export const courseManagementLinks: LinkProps[] = [
  {
    label: 'Course List',
    link: '/admin/course-list',
    icon: 'bullet-point',
    isAdmin: true,
    id: 'course-list',
  },
  {
    label: 'Bundles',
    link: '/admin/bundles',
    icon: 'bullet-point',
    isAdmin: true,
    id: 'bundles',
  },
  {
    label: 'Certificates',
    link: '/admin/certificates',
    icon: 'bullet-point',
    isAdmin: true,
    id: 'certificates',
  },
  {
    label: 'Rewards',
    link: '/admin/rewards',
    icon: 'bullet-point',
    isAdmin: true,
    id: 'rewards',
  },
];

export const adminReportingLinks: LinkProps[] = [
  {
    label: 'Courses Report',
    link: '/admin/reports/courses',
    icon: 'bullet-point',
    id: 'courses-report',
  },
  {
    label: 'Learners Report',
    link: '/admin/reports/learners',
    icon: 'bullet-point',
    id: 'learners-report',
  },
  {
    label: 'Communities Report',
    link: '/admin/reports/groups',
    icon: 'bullet-point',
    id: 'communities-report',
  },
  {
    label: 'Membership Report',
    link: '/admin/reports/membership',
    icon: 'bullet-point',
    id: 'membership-report',
  },
  {
    label: 'Communities Assignment Report',
    link: '/admin/reports/group-course-assignment',
    icon: 'bullet-point',
    id: 'communities-assignment-report',
  },
  {
    label: 'Users Assignment Report',
    link: '/admin/reports/user-course-assignment',
    icon: 'bullet-point',
    id: 'users-assignment-report',
  },
];

export const reportingLinks = (
  permissions: string[],
  facilitatorGroupsIds: string[] = [],
  managerGroupIds: string[] = []
) => {
  const links: LinkProps[] = [];

  // add learner report for all users
  links.push({
    label: 'My Learning Activity',
    link: '/reports/user',
    icon: 'bullet-point',
    id: 'my-learning-activity',
  });

  if (permissions.includes('reports:course:creator')) {
    links.push({
      label: 'Engagement Report',
      link: '/reports/engagement-report',
      icon: 'bullet-point',
      id: 'engagement-report',  
    });
  }

  if (
    permissions.includes('reports:course:facilitator') &&
    (facilitatorGroupsIds.length > 0 || managerGroupIds.length > 0)
  ) {
    links.push({
      label: 'Facilitator Course Report',
      link: '/reports/courses',
      icon: 'bullet-point',
      id: 'facilitator-course-report',
    });
  }

  if (
    permissions.includes('reports:learner') &&
      (facilitatorGroupsIds.length > 0 || managerGroupIds.length > 0)
  ) {
    const groupsIds = facilitatorGroupsIds.concat(managerGroupIds);
    const link =
      groupsIds.length === 1
        ? `/reports/learners/group/${groupsIds[0]}`
        : '/reports/learners';

    links.push({
      label: 'Learner Report',
      link,
      icon: 'bullet-point',
      id: 'learner-report',
    });
  }

  if (
    permissions.includes('reports:community') &&
      (facilitatorGroupsIds.length > 0 || managerGroupIds.length > 0)
  ) {
    const groupsIds = facilitatorGroupsIds.concat(managerGroupIds);
    const link =
      groupsIds.length === 1
        ? `/reports/groups/${groupsIds[0]}`
        : '/reports/groups';
    links.push({
      label: 'Community Report',
      link,
      icon: 'bullet-point',
      id: 'community-report',
    });
  }

  if (
    permissions.includes('reports:course:facilitator') &&
    facilitatorGroupsIds.length > 0
  ) {
    links.push(
      {
        label: 'Communities Assignment Report',
        link: '/reports/group-course-assignment',
        icon: 'bullet-point', 
        id: 'communities-assignment-report',
      },
      {
        label: 'Users Assignment Report',
        link: '/reports/user-course-assignment',
        icon: 'bullet-point',
        id: 'users-assignment-report',
      }
    );
  }

  return links;
};

export const facilitatorDdlLinks = (permissions: string[]) => {
  const links: LinkProps[] = [];

  if (permissions.includes('communities:facilitator')) {
    links.push({
      label: 'Org Management',
      link: '/admin/ddl/org-management',
      icon: 'lock',
      id: 'org-management',
    });
  }

  links.push({
    label: 'Cronjobs',
    link: '/admin/cronjobs',
    icon: 'organizations',
    isAdmin: true,
    id: 'cronjobs',
  });

  return links;
};

export const adminPanelLinks = () => {
  const links: LinkProps[] = [];

  links.push({
    label: 'Npm Logs',
    link: '/admin/npmlogs',
    icon: 'folder',
    isAdmin: true,
    id: 'npm-logs',
  });

  if (isProdOrDev()) {
    links.push({
      label: 'Loops',
      link: '/admin/loops',
      icon: 'mail',
      isAdmin: true
    });
  }

  return links;
};

export const userDdlLinks = (ddl: {
  basketSize: boolean;
  houseBrands: boolean;
  hasDutchieIntegration: boolean;
}) => {
  const links: LinkProps[] = [];

  if (ddl.basketSize) {
    links.push({
      label: 'Ticket Size',
      link: '/ddl/ticket-size',
      icon: 'basket'
    });
  }

  if (ddl.houseBrands) {
    links.push({
      label: 'House Brands',
      link: '/ddl/house-brands',
      icon: 'bullet-point',
    });
  }

  if (ddl.hasDutchieIntegration) {
    links.push({
      label: 'Scorecard',
      link: '/ddl/performance-report',
      icon: 'changesTracker',
    });
  }

  return links;
};

export const userLinks: LinkProps[] = [
  {
    label: 'Dashboard',
    link: '/',
    icon: 'dashboard',
    id: 'user-dashboard',
  },
  {
    label: 'Course Library',
    link: '/courses',
    icon: 'course-library',
    id: 'user-course-library',
  },
];

export const facilitatorCommunitiesSubItemsLinks: LinkProps[] = [
  {
    label: 'Your Communities',
    link: '/communities',
    icon: 'bullet-point',
    id: 'facilitator-your-communities',
  },
  {
    label: 'Pending Members',
    link: '/communities/pending-members',
    icon: 'bullet-point',
    checkNotifications: true,
    id: 'facilitator-pending-members',
  },
];

export const facilitatorLinks: LinkProps[] = [
  {
    label: 'User Management',
    link: '/user-management',
    icon: 'user-management',
    id: 'facilitator-user-management',
  },
];

export const organizationAdminLinks: LinkProps[] = [
  {
    label: 'Organization Management',
    link: '/organization-management',
    icon: 'organizations',
    id: 'org-admin-organization-management',
  },
];

export const profileLinks = [
  {
    icon: FolderSharedOutlinedIcon,
    label: 'Profile',
    link: '/profile',
    id: 'profile',
  },
];

export const courseBuilderLinks = (
  hasCourseAssignmentPermissions: boolean,
  isCourseBuilder: boolean
) => {
  const links: LinkProps[] = [];

  if (isCourseBuilder) {
    links.push({
      icon: 'bullet-point',
      label: 'Course List',
      link: '/course-list',
      id: 'course-builder-course-list',
    });

    links.push({
      icon: 'bullet-point',
      label: 'Bundles',
      link: '/bundles',
      id: 'course-builder-bundles',
    });
  }

  if (hasCourseAssignmentPermissions) {
    links.push({
      icon: 'bullet-point',
      label: 'Course Assignment',
      link: '/course-assignment',
      id: 'course-builder-course-assignment',
    });
  }

  return links;
};
